@import "styles";

.container {
  flex-shrink: 0;
  flex-grow: 0;
  z-index: $layer-header;
}

.upgradeBanner {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: calc(100vw - 320px);
  background: $warm-sand;
  padding: 16px;
  border-radius: 0 0 16px 16px;
  box-sizing: border-box;
  width: max-content;
  animation: slideDown 300ms 300ms cubic-bezier(0, 1, .5, 1) backwards;

  @keyframes slideDown {
    from { transform: translate(-50%, -100%); }
  }

  &.fullWidth {
    position: relative;
    max-width: 100%;
    width: 100%;
    border-radius: 0;
    animation: none;
  }

  &.danger {
    background: $dusty-pink;

    svg {
      fill: $pillar-box;
    }
  }

  .content {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  svg {
    fill: $fields-of-barley;
  }

  span {
    @include paragraph16Regular();
  }

  a {
    @include paragraph16SemiBold();

    text-decoration: none;
    color: $grid-purple;
    margin-left: 8px;

    &:nth-of-type(2) {
      margin-left: 4px;
    }
  }

  @media (max-width: $designs-mobile) {
    .content {
      gap: 16px;
    }
  }
}