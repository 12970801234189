@import "styles";

.verifyEmail {
  p {
    margin: 0;

    &:not(:first-of-type) {
      margin-top: 16px;
    }
  }

  .response {
    @include paragraph14Regular();
  }
}
