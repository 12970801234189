@import "styles";

.kpiContainer {
  display: flex;
  justify-content: space-around;
  gap: 32px;
  margin-bottom: 16px;
}

.description {
  max-width: 400px;
  text-align: center;
  margin-top: 8px;

  @include paragraph16Regular();
}

.container {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.button {
  margin-top: 24px;
  margin-bottom: 16px;
}