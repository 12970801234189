@import "styles";

.items {
  margin: 0 -16px;
  height: 100%;
  overflow-y: auto;
  position: relative;
  margin-bottom: -16px;

  &::after {
    content: "";
    width: calc(100% - 10px);
    height: 24px;
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      to top,
      rgb(251 251 251 / 100%) 0%,
      rgb(251 251 251 / 0%) 100%
    );
  }

  &.includeBorder {
    border-bottom: 1px solid $mist;
  }

  .group {
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    margin-bottom: 8px;

    &:hover {
      cursor: pointer;
      background-color: $mist;
    }

    .contents {
      display: flex;
      flex-direction: column;
      max-width: calc(100% - 32px);
    }

    .text {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      align-items: baseline;
    }

    .subTitle {
      line-height: 16px;
      font-size: 12px;
      color: $fossil;
      white-space: nowrap;
    }

    .title {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: $squid-ink;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  @media (min-width: $designs-mobile) {
    max-height: 244px;
  }
}

.overviewControls {
  display: flex;
  flex-direction: row;
  padding-bottom: 24px;

  .groupName {
    flex-grow: 1;
    margin-right: 16px;
  }
}
