@import "styles";

$viewportSpacing: 40px;
$headerHeight: 56px;

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $layer-modal-overlay;
  background: rgba($black, .05);
  backdrop-filter: blur(4px);
  -webkit-tap-highlight-color: $transparent;
  touch-action: none;
  animation: fadeIn 800ms cubic-bezier(0, 1, .5, 1);

  @keyframes fadeIn {
    from { opacity: 0; }
  }

  @media (max-width: $designs-mobile) {
    backdrop-filter: none;
  }
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: $layer-modal;
  background-color: $white;
  box-shadow: $shadow-2xl;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;

  @media (max-width: $designs-mobile) {
    transform: translateY(-100%);
    height: fit-content;
    top: 100%;
    border-radius: 16px 16px 0 0;
    box-shadow: 0 8px 32px rgba($black, 0.25);
    animation: slideUp 200ms $grid-ease-in;

    @keyframes slideUp {
      from { transform: translateY(0); }
    }
  }

  @include breakpoint(medium) {
    overflow: hidden;
    border-radius: 8px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 644px;
    height: unset;
    max-height: calc(100% - #{$viewportSpacing});
    animation: slide 400ms cubic-bezier(0, 1, .5, 1);

    @keyframes slide {
      from {
        opacity: 0;
      }
    }

    &[data-size="small"] {
      max-width: 422px;
    }

    &[data-size="small-medium"] {
      max-width: 528px;
    }

    &[data-size="large"] {
      max-width: 866px;
    }

    &[data-size="x-large"] {
      max-width: 1090px;
    }
  }

  .header {
    height: $headerHeight;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $wise-owl;
    flex: 0 0 56px;

    h1 {
      @include heading20Medium();

      font-weight: 600;
      flex: 1;
      margin: 0 0 0 16px;
      color: $squid-ink;
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 8px;

      @include breakpoint(medium) {
        margin: 0 0 0 24px;
      }
    }

    button {
      display: block;
      margin-right: 8px;
    }

    @media (max-width: $designs-mobile) {
      flex: 0 0 40px;
      background: $sweet-rocket;
      border-radius: 16px 16px 0 0;

      h1 {
        @include label12Medium();

        color: $grid-purple;
      }

      button {
        padding: 8px;
        margin-right: 0;

        svg {
          fill: $grid-purple;
        }
      }
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-flow: column;
    min-height: 0;
    font-size: 16px;
    line-height: 22px;
  }

  .outer {
    overflow: auto;
  }

  .outer.animateHeight {
    max-height: none;
    transition: height 300ms cubic-bezier(0, 1, .5, 1);

    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }

  .scrollable {
    flex-grow: 1;
    min-height: 0;
    overflow-x: hidden;
    overflow-y: hidden;

    &.scroll {
      overflow-y: auto;
    }
  }

  .body:not(.noPadding) {
    padding: 16px;

    @include breakpoint(medium) {
      padding: 16px 24px;
    }
  }

  .footer {
    margin: 16px;

    @include breakpoint(medium) {
      margin: 24px;
    }
  }
}

/* stylelint-disable property-no-unknown, property-case, declaration-empty-line-before */
/* stylelint-disable-next-line */
:export {
  viewportSpacing: $viewportSpacing;
  headerHeight: $headerHeight;
}
