@import "styles";

.tooltip {
  @include label12Regular();

  background-color: $squid-ink;
  color: $white;
  z-index: $layer-tooltip;
  position: fixed;
  padding: 4px 8px;
  border-radius: 4px;
  box-sizing: border-box;
  max-width: max-content;
  white-space: nowrap;
  text-align: center;
  transition: opacity 200ms;
  animation: fadeIn 200ms 20ms;
  animation-fill-mode: backwards;

  &.light {
    background-color: $cloud;
  }

  @keyframes fadeIn {
    from { opacity: 0; }
  }

  &.wrapLabel {
    max-width: 200px;
    white-space: normal;
    text-wrap: balance;
  }

  &.hide {
    opacity: 0;
  }

  &.bottom {
    margin-top: 4px;
  }

  &.top {
    margin-top: -4px;
  }

  &.right {
    margin-left: 4px;
  }

  &.left {
    margin-left: -4px;
  }

  .arrow {
    position: fixed;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 4px solid $squid-ink;

    &.light {
      border-right: 4px solid $cloud;
    }

    &.hide {
      display: none;
    }

    &.bottom {
      transform: rotate(90deg);
      margin-top: -4px;
    }

    &.top {
      transform: rotate(270deg);
      margin-top: -8px;
    }

    &.left {
      transform: rotate(180deg);
      margin-left: -4px;
    }

    &.right {
      transform: rotate(0deg);
      margin-right: -4px;
    }
  }

  a {
    color: $white;

    &:hover {
      color: $white;
    }
  }
}

.anchor {
  display: none;
}
