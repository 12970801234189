@import "styles";

.subtleButton {
  @include resetButton();

  color: $candy-apple;
  width: 100%;
  height: 36px;
  line-height: 20px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px;

  &.loadMore {
    color: $aster;
  }

  &:hover,&:focus {
    font-weight: 600;
    outline: none;
  }
}
