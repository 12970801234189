@import "styles";

.welcomeAnimation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: $white;
  z-index: $layer-welcomeAnimation;
  display: flex;

  &.dissapear {
    animation: fadeOut 400ms 400ms;
    animation-fill-mode: forwards;

    @keyframes fadeOut {
      to { opacity: 0; }
    }

    > * {
      opacity: 0;
    }
  }

  > * {
    transition: opacity 400ms;
  }
}
