@import "styles";

.navigation {
  &.side {
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-top: 16px;
    border-right: 1px solid $wise-owl;
    box-sizing: border-box;
  }

  &.sideNav {
    height: 100%;
    display: flex;
    flex-flow: column;
    flex-shrink: 0;
    padding-top: 16px;
    border-right: 1px solid $wise-owl;
    box-sizing: border-box;
    width: 240px;

    &.hideMenus {
      border: none;
    }

    .logo {
      padding: 8px 18px;
      display: flex;
      justify-content: center;
    }

    .horizontalSeperator {
      width: 100%;
      height: 1px;
      background: $wise-owl;
      margin: 16px 0;
    }

    .groupHeading {
      @include label10Medium();

      padding-left: 8px;
      margin-top: 16px;
    }

    .menu {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-left: 16px;

      > * {
        display: block;
      }

      .navButton, .flagToggler {
        a {
          color: $squid-ink;
          text-decoration: none;
        }

        button {
          @include resetButton();

          width: 100%;
          display: flex;
          align-items: center;
          gap: 8px;

          &.selected {
            svg {
              background: $lavender;
              color: $grid-purple;
            }
          }

          &:hover {
            svg {
              background: $lavender;
              color: $grid-purple;
            }
          }

          svg {
            padding: 8px;
            border-radius: 8px;
          }

          span {
            @include paragraph14Regular();
          }
        }
      }
    }

    .userMenu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 8px 16px 16px;

      &.notLoggedIn {
        justify-content: center;
      }
    }
  }

  &.mobileSideNav {
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(173.53deg, $grid-purple -0.34%, $lilac 100.29%);
    z-index: $layer-mobileNav;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-bottom: 32px;
    box-sizing: border-box;
    overflow-y: auto;
    transition: transform 200ms ease-in;
    transform: translateX(-100vw);

    &.show {
      transform: translateX(0);
      transition: transform 400ms cubic-bezier(0, 1, .5, 1);
    }

    .close {
      @include resetButton();

      padding: 16px;
      position: absolute;
      left: 0;
      top: 0;

      svg,
      path {
        color: $white;
      }
    }

    .flagToggler {
      position: absolute;
      padding: 16px;
      right: 0;
      top: -4px;
      transform: scale(0.75);

      button {
        @include resetButton();

        margin: 0;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          display: none;
        }
      }
    }

    .avatar {
      border: 2px solid $mist;
      margin-top: 40px;
      flex-shrink: 0;
      display: block;
      flex-basis: 104px;
    }

    .name {
      @include display24Bold();

      color: $white;
      margin-top: 16px;
    }

    a {
      text-decoration: none;
    }

    button {
      background-color: transparent !important;
      border-color: $white !important;
      color: $white !important;
      margin-top: 16px;

      svg,
      path {
        fill: $white !important;
      }
    }

    .links {
      width: calc(100% - 128px);
      margin-top: 24px;

      a {
        @include heading20Medium();

        color: $white;
        text-decoration: none;
        padding: 16px 0;
        display: block;
        position: relative;

        &:not(:first-of-type)::before {
          content: "";
          height: 1px;
          width: 72px;
          background: $white;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  .flex {
    flex-grow: 1;
  }
}

.tooltip {
  @include label12Regular();

  background: $squid-ink;
  color: $white;
  position: fixed;
  margin-top: 4px;
  margin-left: 18px;
  width: max-content;
  padding: 4px 8px;
  border-radius: 4px;
  transition: opacity 200ms;
  z-index: $layer-tooltip;

  &::before {
    content: "";
    position: absolute;
    left: -4px;
    top: 6px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 4px solid $squid-ink;
  }

  &.hide {
    opacity: 0;
  }
}
