@import "styles";

.flagToggler {
  p {
    @include paragraph14Regular();

    margin-bottom: 24px;
  }

  h2 {
    @include paragraph16SemiBold();

    margin-top: 24px;
    margin-bottom: 16px;
  }

  .flex {
    flex-grow: 1;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 24px;
    margin-bottom: 8px;
  }

  .flags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px 80px;

    > div {
      flex-basis: 40%;
      flex-grow: 1;
    }

    .element {
      display: flex;
      flex-flow: row;
      align-items: center;

      label {
        @include paragraph14Regular();

        flex-grow: 1;
        margin-left: 16px;

        &.json {
          max-width: 160px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: $ash;
        }
      }

      .input {
        width: initial;
        min-width: 0;
        height: 22px;
        font-size: 16px;
        padding: 0;
      }

      .identifier {
        @include label12Medium();

        width: 32px;
        text-align: center;
        color: $ash;
      }
    }
  }
}
