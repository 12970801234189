@import "styles";

.toast {
  @include tooltip();

  display: flex;
  padding: 0;
  min-height: 44px;
  font-size: $font-size-medium;
  z-index: $layer-toast;
  animation: fadeIn 480ms ease forwards;

  &.onClick {
    cursor: pointer;
  }

  &.hover:hover {
    background-color: $squid-ink;
  }

  .message {
    padding: 10px 16px;
    display: flex;
    align-items: center;

    &.hasIcon {
      padding-left: 0;
    }

    &.hasButton {
      border-right: 0.5px solid;
    }
  }

  .icon {
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ctaButton {
    display: flex;
    align-items: center;
    height: inherit;
    padding: 9px 16px;
    font-size: 16px;
    font-weight: 600;
    color: $white;
    text-decoration: none;

    &:hover {
      background-color: $squid-ink;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
