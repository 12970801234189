@import "styles";

.popOver {
  display: flex;
  justify-content: center;
  position: relative;

  .outer {
    position: absolute;
    top: calc(100% + 16px);
    background: $white;
    border-radius: 8px;
    box-shadow: 0 4px 4px rgba($squid-ink, 0.04),
      0 10px 15px rgba($squid-ink, 0.1);
    transform-origin: top;
    max-height: min(calc(100vh - 80px), 800px);
    overflow-y: auto;

    &.animateHeight {
      max-height: none;
      transition: height 200ms ease-out;
    }
  }

  &.shouldAnimate {
    position: unset;

    .outer {
      top: auto;
      animation: bottomRightCorner 480ms;
      bottom: 10px;
      left: 0;
      z-index: 3;
    }
  }

  &.fromLeft {
    .outer {
      animation: fromLeft 480ms;
    }
  }

  &.positionRight .outer {
    right: 0;
    left: auto;
  }

  .dialog {
    transition: width 200ms;
  }

  @keyframes bottomRightCorner {
    from {
      opacity: 0;
      transform: translateY(24px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fromLeft {
    from {
      opacity: 0;
      transform: translateX(-24px);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .content {
    display: flex;
    flex-flow: column;
  }
}
