@import "styles";

.button {
  @include resetButton();

  min-width: max-content;
  max-height: 48px;
  border: none;
  font-size: 18px;
  padding: 8px 24px;
  font-weight: normal;
  border-radius: 28px;
  line-height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  outline: none;

  &:disabled:hover {
    cursor: not-allowed;
  }

  .icon {
    margin-right: 13px;
    display: flex;

    &.iconAfter {
      margin-left: 13px;
      margin-right: 0;
    }
  }

  &.medium-large {
    max-height: 40px;
    padding: 8px 16px;
    font-size: 16px;
    line-height: 24px;

    .icon {
      margin-right: 12px;

      &.iconAfter {
        margin-left: 12px;
        margin-right: 0;
      }
    }
  }

  &.medium {
    max-height: 32px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 20px;

    .icon {
      margin-right: 8px;

      &.iconAfter {
        margin-left: 8px;
        margin-right: 0;
      }
    }
  }

  &.small {
    max-height: 24px;
    padding: 2px 12px;
    font-size: 14px;
    line-height: 20px;

    .icon {
      margin-right: 8px;

      &.iconAfter {
        margin-left: 8px;
        margin-right: 0;
      }
    }
  }

  &.primary {
    color: $white;
    background: linear-gradient(
      90deg,
      $lilac 0%,
      $lupine 19.27%,
      $grid-purple 77.6%,
      $lilac 100%
    );
    background-size: 300% 100%;
    background-position: 100% 0%;
    transition: all 240ms ease;

    &:hover {
      background-position: 18% 0%;
      transition: background-position 240ms ease;
    }

    &:active {
      background-position: 50% 0%;
      transition: background-position 160ms ease;
    }

    &::after {
      content: "";
      position: absolute;
      top: -2px;
      left: -2px;
      z-index: -1;
      height: calc(100%);
      width: calc(100%);
      border-radius: inherit;
      border-width: 2px;
      border-style: solid;
      border-image-slice: 1;
      background-image: $white;
      opacity: 0;
      transition: opacity 240ms 120ms ease;
    }

    &::before {
      content: "";
      position: absolute;
      top: -6px;
      left: -6px;
      z-index: -1;
      height: calc(100% + 8px);
      width: calc(100% + 8px);
      border-radius: 56px;
      border-width: 2px;
      border-style: solid;
      border-image-slice: 1;
      background-image: linear-gradient(90deg, $grid-purple 0%, $lilac 100%);
      transform: scale(0.9);
      opacity: 0;
      transition: transform 240ms 80ms ease, opacity 240ms ease;
    }

    &:focus[data-focus-visible-added] {
      background-position: 100% 0%;
      transform: none;
      transition: none;

      &::after {
        opacity: 1;
        transition: opacity 240ms 80ms ease;
      }

      &::before {
        opacity: 1;
        transform: scale(1);
        transition: transform 120ms ease, opacity 120ms ease;
      }

      &:hover {
        background-position: 100% 0%;
        transform: none;
        transition: none;
      }
    }

    &:disabled {
      background: $lilac;

      &:hover {
        box-shadow: none;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  &.secondary,
  &.tertiary {
    outline: 2px solid $transparent;
    outline-offset: -2px;
    background-color: transparent;
    color: $grid-purple;
    border: 1px solid $grid-purple;
    transition: all 240ms ease;

    &:hover {
      background-color: $lavender;
      transition: background-color 240ms ease;
    }

    &:active {
      background-color: $sweet-rocket;
      transition: background-color 240ms ease;
    }

    &:disabled {
      background-color: $white;
      transition: all 240ms ease;
      color: $lilac;
      border-color: $lilac;

      .icon path {
        fill: $lilac;
      }
    }

    .icon path {
      fill: $grid-purple;
    }

    &:focus[data-focus-visible-added] {
      outline: 2px solid $grid-purple;
      outline-offset: 2px;
      transition: all 240ms ease;
      background-color: $sweet-rocket;
      border-color: $transparent;

      &.medium {
        outline-offset: 3px;
      }

      &.small {
        outline-offset: 3px;
      }
    }
  }

  &.secondary {
    &.yellow {
      color: $squid-ink;
      border-color: $fields-of-barley;
      background-color: $lemon-meringue;

      &:hover {
        background-color: $warm-sand;
      }

      &:active {
        background-color: rgba($fields-of-barley, 0.5);
      }

      &:focus[data-focus-visible-added] {
        outline: 2px solid $fields-of-barley;
        background-color: $warm-sand;
      }

      .icon path {
        fill: currentColor;
      }

      &:disabled {
        color: $fossil;
        border-color: $fossil;
        background-color: transparent;
      }
    }

    &.purple {
      color: $squid-ink;
      border-color: $lilac;
      background-color: $lavender;

      &:hover {
        background-color: $sweet-rocket;
      }

      &:active {
        background-color: rgba($lilac, 0.5);
      }

      &:focus[data-focus-visible-added] {
        outline: 2px solid $lilac;
        background-color: $sweet-rocket;
      }

      .icon path {
        fill: currentColor;
      }

      &:disabled {
        color: $fossil;
        border-color: $fossil;
        background-color: transparent;
      }
    }
  }

  &.tertiary {
    border-color: $transparent;

    &:disabled {
      border-color: $transparent;
    }
  }

  &.destructive,
  &.secondaryDestructive {
    outline: 2px solid $transparent;
    outline-offset: -2px;
    border: 1px solid $candy-apple;
    color: $candy-apple;
    background-color: $white;
    transition: all 240ms ease;

    &:hover {
      background-color: $red-hover-1;
      transition: background-color 240ms ease;
    }

    &:active {
      background-color: $red-hover-2;
      transition: background-color 240ms ease;
    }

    &:disabled {
      background-color: $red-hover-2;
      color: rgba($candy-apple, 0.3);
      transition: background-color 240ms ease;
      border-color: rgba($candy-apple, 0.3);
    }

    &:focus[data-focus-visible-added] {
      outline: 2px solid $candy-apple;
      outline-offset: 2px;
      transition: all 240ms ease;
      background-color: $red-hover-2;
      border-color: $transparent;

      &.medium {
        outline-offset: 3px;
      }

      &.small {
        outline-offset: 3px;
      }
    }
  }

  &.secondaryDestructive {
    border: 1px solid $transparent;

    &:disabled {
      background-color: $transparent;
      color: rgba($candy-apple, 0.3);
      transition: background-color 240ms ease;
      border-color: transparent;
    }
  }
}
