@import "styles";

.privacyForm {
  margin: 0 $gutter;

  @media (min-width: $designs-mobile) {
    margin-top: 128px;
  }

  h1 {
    font-family: $font-serif;
    font-weight: 600;
    font-size: 42px;
    line-height: 125%;
    margin: 16px 0;

    @media (max-width: $designs-mobile) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .textBlock {
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 24px;
  }

  .detailsButton {
    padding: 0;
    margin: 0;
    height: initial;
    color: $aster;
    text-decoration: underline;

    &:hover {
      background: none;
      border-color: transparent;
    }
  }

  .checkboxes {
    display: inline-block;
    text-align: left;
    margin-bottom: 32px;

    > * {
      margin-bottom: 8px;
    }
  }

  .actions {
    @include footerButtons();
  }

  .betaThanks {
    margin-bottom: 56px;
  }

  .formInvalid {
    opacity: 0;
    color: $candy-apple;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    margin-left: 28px;
    margin-top: -8px;

    &.show {
      opacity: 1;
      transition: opacity 400ms;
    }
  }
}
