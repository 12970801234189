@import "styles";

.notifications {
  height: 32px;
  position: relative;
}

.notificationsButton {
  width: 46px !important;
  border-radius: 16px !important;
  background: transparent !important;

  &.notificationsButtonSmall {
    width: auto !important;
  }
}

.notificationIndicator {
  border-radius: 50%;
  border: 2px solid $white;
  background-color: $candy-apple;
  position: absolute;
  width: 12px;
  height: 12px;
  right: 6px;
  top: 0;
  pointer-events: none;

  &.mobile {
    right: 0;
  }
}

.notificationPanel {
  width: 324px;
  position: fixed;
  left: 16px;
  bottom: 64px;
  z-index: 1;
  background-color: $white;
  box-shadow: 0 4px 4px rgba($squid-ink, 0.04),
    0 10px 15px rgba($squid-ink, 0.1);
  border-radius: 0 0 8px 8px;
  overflow: hidden;

  .header {
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 0 8px;

    .title {
      font-weight: 600;
      font-size: 20px;
      font-family: $font-serif;
      padding: 8px 8px 8px 0;
      color: $squid-ink;
    }

    .markAllAsRead {
      margin: 16px 8px 8px 0;
    }
  }

  .list {
    padding: 0 8px 16px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    max-height: calc(100vh - #{$head-height} - 88px);
  }

  .emptyState {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 188px;
      max-width: 188px;
      height: 194px;
    }

    .title {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      margin: 0;
      padding: 20px 0 8px;
    }

    .subTitle {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      margin: 0;
      padding-bottom: 56px;
    }
  }

  &.mobile {
    z-index: $layer-modal;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;

    .header {
      align-items: center;

      .title {
        align-self: center;
        padding: 0;
      }

      .close {
        align-self: center;
      }
    }

    .list {
      overflow-y: auto;
      overflow-x: hidden;
      height: calc(100vh - #{$head-height});
      max-height: calc(100vh - #{$head-height});

      .item {
        width: 100%;
      }
    }
  }
}

.item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 76px;
  width: 300px;
  margin-bottom: 8px;
  border: 1px solid $mist;
  box-sizing: border-box;
  border-radius: 8px;
  color: $squid-ink;
  text-decoration: none;

  &:hover {
    box-shadow: 0 2px 7px rgba($squid-ink, 0.04),
      0 4px 6px rgba($squid-ink, 0.05);
    text-decoration: none;
  }

  &:focus[data-focus-visible-added] {
    border-radius: 8px;
    box-shadow: none;
  }

  &:focus-visible {
    outline: none;
  }

  &:focus:not(:active) {
    border: 2px solid $transparent;

    @include defaultFocus();

    /* the extra thick border makes child elements jump around unless we make adjustments */
    .avatar {
      padding: 7px 12px 0 11px;

      .badge {
        top: 29px;
        left: 31px;
      }
    }

    .contents {
      padding: 7px 0;
    }

    .dotContainer {
      padding: 7px 7px 7px 8px;
    }
  }

  blockquote {
    margin: 10px 0 0;
    color: $ash;
    border-left: 2px solid $ash;
    padding-left: 10px;
  }

  &:active {
    background: $wise-owl;
    border: 1px solid $wise-owl;
  }

  .avatar {
    width: 40px;
    height: 40px;
    padding: 8px 12px 0;
    position: relative;

    .badge {
      border-radius: 50%;
      height: 24px;
      width: 24px;
      position: absolute;
      top: 30px;
      left: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;

      &.comment {
        background-color: $white;
        border: 2px solid $olive-green;

        svg {
          fill: $olive-green;
        }
      }

      &.sharedDocument {
        background-color: rgb(216 229 246);
        border: 2px solid $aster;

        svg {
          fill: $aster;
        }
      }

      &.likedDocument {
        background-color: rgb(255 94 96);
        border: 2px solid $candy-apple;

        svg {
          fill: $white;
        }
      }

      &.documentMilestoneReached {
        background-color: $white;
        border: 2px solid $grid-purple;

        svg {
          fill: $grid-purple;
        }
      }

      &.spreadsheetUpdateFailed {
        background-color: rgb(255 235 214);
        border: 2px solid $sunflower;

        svg {
          fill: $sunflower;
        }
      }

      &.groups {
        background-color: $mist;
        border: 2px solid $ash;

        svg {
          fill: $ash;
        }
      }
    }
  }

  .contents {
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    width: 100%;

    .main {
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 20px;
      font-size: 14px;
      width: 100%;

      strong,
      blockquote {
        word-break: break-word;
      }
    }

    .date {
      padding-top: 4px;
      text-align: right;
      height: 20px;
      line-height: 16px;
      font-size: 12px;
      color: $fossil;
    }
  }

  .dotContainer {
    width: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    padding: 8px;

    .dot {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: $aster;
    }
  }

  &.read > * {
    opacity: 0.6;
  }
}
