@import "styles";

.description {
  display: flex;
  flex-flow: column;
}

.buttonGroup {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 16px;
}