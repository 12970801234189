@import "styles";

.container {
  display: flex;
  font-size: 8px;
  justify-content: space-around;
  padding: 0 40px;
}

.kpi {
  align-items: center;
  display: flex;
  flex-flow: column;
  padding: 24px 16px;
  background: $lavender;
  border-radius: 16px;
  flex: 144px 1 0;
}

.kpiValue {
  display: flex;
  align-items: flex-end;
}

.bigNumber {
  @include display42Bold();

  line-height: 1;
  margin: 0;

  &.warning {
    color: $fields-of-barley;
  }

  &.error {
    color: $candy-apple;
  }
}

.limit {
  @include paragraph14Regular();

  line-height: 1;
  color: $nobel;
}

.kpiLabel {
  @include label10Medium();

  margin-top: 8px;
  text-align: center;
}