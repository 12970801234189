@import "styles";

.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  line-height: normal;

  &.small {
    width: 32px;
    height: 16px;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:focus {
      + .slider {
        box-shadow: 0 0 2px $grid-purple;
      }
    }

    &:checked {
      + .slider {
        &::before {
          transform: translateX(24px);
        }
      }

      + .slider.small {
        &::before {
          transform: translateX(16px);
        }
      }
    }

    &:hover {
      + .slider {
        box-shadow: 0 0 2px $grid-purple;
      }
    }

    &:disabled {
      + .slider {
        background-color: $fossil;
        cursor: not-allowed;

        &.on {
          background-color: $fossil;
        }

        &:hover {
          &.on {
            background-color: $fossil;
          }
        }
      }
    }
  }

  .slider {
    position: absolute;
    display: flex;
    box-sizing: border-box;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $fossil;
    transition: 0.3s $ease-in-out-cubic;
    border-radius: 24px;

    .icon {
      color: $white;
      pointer-events: none;
    }

    &.small {
      border-radius: 16px;

      &::before {
        height: 12px;
        width: 12px;
      }
    }

    &.on {
      background-color: $grid-purple;
    }

    &:hover {
      &.on {
        background-color: $grid-purple;
      }

      &.off {
        background-color: $fossil;
      }
    }

    &::before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 2px;
      background-color: $white;
      transition: 0.3s $ease-in-out-cubic;
      border-radius: 100%;
    }
  }

  &.locked {
    &, input, .slider {
      cursor: not-allowed;
    }

    &.nudge .slider::before {
      animation: nudge 0.45s 1 $ease-in-out-cubic;

      @keyframes nudge {
        0%, 100% {
          transform: translateX(24px);
        }

        75% {
          transform: translateX(8px);
        }
      }
    }

    &.nudge .slider.small::before {
      animation: nudgeSmall 0.45s 1 $ease-in-out-cubic;

      @keyframes nudgeSmall {
        0%, 100% {
          transform: translateX(16px);
        }

        75% {
          transform: translateX(5px);
        }
      }
    }
  }
}
