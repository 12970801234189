@import "styles";

.skeletonLoader {
  z-index: 1;
  width: 100%;
  overflow: hidden;
  position: absolute;
  background: $white;

  &.hide {
    animation: hide 400ms;

    @keyframes hide {
      to { opacity: 0; }
    }
  }

  .skeletons {
    animation: fadeIn 800ms;
    animation-fill-mode: backwards;

    @keyframes fadeIn {
      from { opacity: 0; }
    }
  }

  // Set background of all child elements to the same color.
  section {
    background: $cloud;
    border-radius: 4px;
  }

  // Loading animation effect.
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(90deg, rgba($white, 0) 0%, rgba($white, 0.4) 50%, rgba($white, 0) 100%);
    animation: previousSwipe 1s infinite;

    @keyframes previousSwipe {
      from { transform: translateX(-100%); }
      to { transform: translateX(100%); }
    }
  }
}
