@import "styles";

// Setup of the file
// 1. styling of the button html element
// 2. all styles related to the iconButton itself, primary, secondary and tertiary
// 3. hover, active, disabled and focus state

.button {
  @include resetButton();

  border: none;
  background-color: $transparent;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  .label {
    color: $fossil;
    font-size: 14px;
    padding-right: 8px;
    opacity: 1;
    transition: opacity 200ms ease, visibility 200ms 0ms;
    visibility: visible;
    width: auto;
    height: auto;
    overflow: visible;

    &.hideLabel {
      opacity: 0;
      width: 0;
      height: 0;
      overflow: hidden;
      padding: 0;
      transition: opacity 200ms ease, width 250ms 0ms, height 200ms 0ms, padding 200ms 0ms;
    }
  }

  .iconButton {
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    transition: all 240ms ease;
    outline: none;
    position: relative;

    &.medium {
      height: 32px;
      width: 32px;
      min-width: 32px;
    }

    &.small {
      height: 24px;
      width: 24px;
      min-width: 24px;
    }

    &.primary {
      background: linear-gradient(
        90deg,
        $lilac 0%,
        $lupine 19.27%,
        $grid-purple 77.6%,
        $lilac 100%
      );
      background-size: 300% 100%;
      background-position: 100% 0%;

      svg,
      path {
        color: $white;
      }
    }

    &.secondary {
      background-color: $lavender;

      svg,
      path {
        color: $grid-purple;
      }
    }

    &.tertiary {
      svg,
      path {
        color: $grid-purple;
      }

      &:disabled {
        background: transparent;

        svg,
        path {
          color: $lilac;
        }
      }
    }

    &.grayScale {
      svg,
      path {
        color: $squid-ink;
      }
    }
  }

  &:hover {
    .iconButton {
      &.primary {
        background-position: 18% 0%;
        transition: background-position 240ms ease;
      }

      &.secondary {
        background-color: $sweet-rocket;
      }

      &.tertiary {
        background-color: $lavender;
      }

      &.grayScale {
        background-color: $cloud;

        svg,
        path {
          color: $squid-ink;
        }
      }
    }
  }

  &:active {
    .iconButton {
      &.primary {
        background-position: 50% 0%;
        transition: background-position 160ms ease;
      }

      &.secondary {
        background-color: $lilac;
      }

      &.tertiary {
        background-color: $sweet-rocket;
      }

      &.grayScale {
        background-color: $wise-owl;
      }
    }
  }

  &:disabled {
    cursor: not-allowed;

    .iconButton {
      background-color: $lilac;

      &.tertiary {
        background-color: $cloud;

        svg,
        path {
          color: $fossil;
        }
      }

      &.secondary {
        background-color: $cloud;

        svg,
        path {
          color: $lilac;
        }
      }

      &.grayScale {
        background-color: $transparent;

        svg,
        path {
          color: $fossil;
        }
      }
    }
  }

  &:focus[data-focus-visible-added] {
    .iconButton {
      &.primary {
        outline: 2px solid $grid-purple;
        outline-offset: 1px;

        &:hover {
          background-position: -50% 0%;
          transform: none;
          transition: none;
        }
      }

      &.secondary,
      &.tertiary {
        outline: 2px solid $grid-purple;
        outline-offset: 1px;
        transition: all 120ms ease;
        background-color: $sweet-rocket;
        border-color: $transparent;

        &.medium {
          outline-offset: 3px;
        }
      }

      &.grayScale {
        outline: 2px solid $grid-purple;
        outline-offset: 1px;
        transition: all 120ms ease;
        background-color: $cloud;
        border-color: $transparent;
      }
    }
  }
}
