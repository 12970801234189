@import "styles";

.popOverMargin {
  margin-bottom: -8px;
}

.helperWrapperInSideNav {
  position: absolute;
  bottom: 0;
  z-index: 1;
}

.helpPopOver {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  padding-top: 12px;

  .header {
    padding-bottom: 4px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .heading {
      @include paragraph16Regular();

      color: $squid-ink;
    }
  }

  hr.divider {
    width: 100%;
    margin: 0;
    margin-bottom: 24px;
    border: none;
    border-bottom: 1px solid $wise-owl;
    height: 0;
  }

  .buttonWrapper {
    @include spaceVertically(8px);

    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.contentWrapper {
  @include spaceVertically(24px);
}

.footerWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}

.emailText {
  @include paragraph14Regular();
}

.flagToggler {
  position: absolute;
  bottom: 16px;
  left: 16px;

  svg {
    transform: scale(0.75);
  }
}

.helpButton button {
  border-radius: 8px;
  background-color: $lavender !important;
  border: 0 !important;
}
