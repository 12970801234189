@import "styles";

.description {
  display: flex;
  flex-flow: column;
}

.linkToPricingPlans {
  margin-top: 8px;

  @include paragraph16SemiBold();
}

.buttonGroup {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 16px;

  &.big {
    margin-top: 40px;
    margin-bottom: 8px;
  }
}

.confirmMessage {
  margin-top: 4px;

  @include paragraph14Regular();
}

.pricingPlansLink {
  display: flex;
  align-items: center;
}

.modal {
  position: relative;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(1px);
  background: rgba($white, 0.8);
  animation: fadeIn 400ms;

  @keyframes fadeIn {
    from { opacity: 0; }
  }

  &.hideBackdrop {
    animation: none;
    background: $white;
  }
}
