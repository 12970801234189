@import "styles";

.pill {
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  box-sizing: border-box;
  border-radius: 12px;
  border: 1px solid $ash;
  background-color: $white;
  margin: 2px 0;
  color: $squid-ink;
  transition: background 200ms, opacity 400ms;
  cursor: default;

  &.isFocused {
    background: $wise-owl;
  }

  &:not(:last-of-type) {
    margin-right: 4px;
  }

  &.disabled {
    opacity: .5;
  }

  span {
    font-size: 12px;
    line-height: 16px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  button {
    @include resetButton();

    display: flex;
    margin-left: 6px;

    &:focus {
      @include defaultFocus();
    }
  }

  .icon {
    display: flex;
    margin-right: 4px;
  }

  .close {
    color: $ash;
    cursor: pointer;
  }
}
