@import "../gridstyles.module";

/* stylelint-disable-next-line value-keyword-case */
$font-mono: DecimaMono, Consolas, Monaco, monospace;

$layout-padding: 7px;

@media print {
  html,
  html * {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important;                 /* Firefox 48 – 96 */
    print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
  }
}

@page {
  margin: 0;
}

#grid-doc-body {
  width: 100%;

  > * {
    max-width: $doc-width - ($layout-padding * 2);
    box-sizing: border-box;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    @media print {
      max-width: unset !important;
    }
  }

  > .grid-layout {
    max-width: $doc-width + $column-padding-lr;
  }

  @media print {
    > *:not(.grid-layout) {
      padding-left: $column-padding-lr;
      padding-right: $column-padding-lr;
    }
  }

  > .lw_wide {
    max-width: $wide-width - ($layout-padding * 2) !important;
  }

  > .lw_full {
    max-width: 100vw !important;
  }
}

.pageBreakAfter {
  page-break-after: always;
  break-after: page;

  @media print {
    page-break-after: always;
  }
}

.grid-doc {
  font-optical-sizing: none;
  text-size-adjust: none;
  font-size: 19px;
  line-height: 28px;

  --label-font-size: 0.79em;
  --h1-size: 34px;
  --h2-size: 28px;
  --h3-size: 22px;
  --h4-size: 20px;
  --h5-size: 19px;
  --h6-size: 19px;

  &.embed {
    padding: 0 0 2px;
  }

  #grid-doc-body {
    padding: 0 $layout-padding;
    box-sizing: border-box;

    @media print {
      padding: 15mm;
    }
  }

  @media (max-width: $designs-tablet) {
    font-size: 18px;

    --label-font-size: 0.74em;
  }

  @media (max-width: $designs-mobile) {
    --h1-size: 28px;
    --h2-size: 22px;
    --h3-size: 20px;
    --h4-size: 18px;
    --h5-size: 18px;
    --h6-size: 18px;
  }

  @media print {
    font-size: 13pt;
    line-height: 1.5;

    --label-font-size: 10pt;
    --h1-size: 20pt;
    --h2-size: 18pt;
    --h3-size: 16pt;
    --h4-size: 13pt;
    --h5-size: 13pt;
    --h6-size: 13pt;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: var(--title-font) !important;
    font-weight: 500;
    caret-color: var(--text-color);

    strong {
      font-weight: 700;
    }
  }

  .ws-text,
  .grid-block,
  .grid-layout,
  blockquote,
  pre,
  p {
    caret-color: var(--text-color);
    margin-top: 0;
    margin-bottom: 0;
  }

  pre,
  code {
    background-color: var(--color-95);
    font-family: $font-mono;
  }

  pre {
    padding: 1rem $step-x;
    font-size: 15px;
    line-height: 1.4;
    overflow-x: auto;
  }

  ::selection {
    color: var(--text-color);
    background: var(--selection-color);
  }

  // view mode should expose empty paragraphs
  p.empty:empty::before {
    content: " ";
    white-space: pre;
  }

  p.collapsed {
    display: none;
  }

  .align-center:is(h1, h2, h3, h4, h5, h6, p, blockquote) {
    text-align: center;
  }

  .align-right:is(h1, h2, h3, h4, h5, h6, p, blockquote) {
    text-align: right;
  }

  code {
    margin: 0 -1px;
    padding: 0 1px;
    box-sizing: border-box;
    font-size: .92em;
  }

  pre code {
    font-size: inherit;
  }

  blockquote em {
    font-style: normal;
  }

  .hr {
    border: 0;
    height: 24px;
    margin: 0;
    background: none;
  }

  blockquote {
    padding: 0 0 0 calc(#{$step-x} - 2px);
    margin: 0;
    font-style: italic;
  }

  // headings
  h1 {
    font-size: var(--h1-size) !important;
    line-height: calc(var(--h1-size) * 1.273);
    margin: 0;
  }

  h2 {
    font-size: var(--h2-size) !important;
    line-height: calc(var(--h2-size) * 1.273);
    padding-top: 10px;
    margin: 0;
  }

  h3 {
    font-size: var(--h3-size) !important;
    line-height: calc(min(26, var(--h3-size) * 1.273));
    padding-top: 4px;
    margin: 0;
  }

  h4 {
    font-size: var(--h4-size) !important;
    line-height: calc(min(26, var(--h4-size) * 1.273));
    padding-top: 6px;
    margin: 0;
  }

  h5, h6 {
    font-size: inherit;
    padding-top: 6px;
    margin: 0;
  }

  // links
  a {
    color: var(--link-color);
    text-decoration: var(--link-decoration);
  }

  a:focus {
    box-shadow: 0 0 0 1.5px var(--focus-color);
    text-decoration: underline;
  }

  a:hover {
    color: var(--link-hover-color);
    text-decoration: underline;
  }

  .isFocusTarget {
    position: relative;

    &::after {
      content: "";
      border-radius: 2px;
      position: absolute;
      top: 4px;
      left: -10px;
      width: 2px;
      height: 100%;
      background: var(--focus-color);
      animation: scale 200ms;

      @keyframes scale {
        from {
          transform: scaleY(0);
        }
      }
    }
  }

  // lists
  .ol::before {
    padding-left: 3px;
  }

  .ol.indent-0 {
    position: relative;
    counter-increment: counter-0;

    &::before {
      content: counter(counter-0) ".";
    }
  }

  .ol.indent-1 {
    position: relative;
    counter-increment: counter-1;

    &::before {
      content: counter(counter-1) ".";
    }
  }

  .ol.indent-2 {
    position: relative;
    counter-increment: counter-2;

    &::before {
      content: counter(counter-2) ".";
    }
  }

  .ol.indent-3 {
    position: relative;
    counter-increment: counter-3;

    &::before {
      content: counter(counter-3) ".";
    }
  }

  :not(.ol) + .ol {
    list-style: none;
    counter-reset: counter-0 counter-1 counter-2 counter-3;
  }

  .ol.indent-0 + .indent-1 {
    list-style: none;
    counter-reset: counter-1 counter-2 counter-3;
  }

  .indent-0 + .indent-2,
  .indent-1 + .indent-2 {
    list-style: none;
    counter-reset: counter-2 counter-3;
  }

  .indent-0 + .indent-3,
  .indent-1 + .indent-3,
  .indent-2 + .indent-3 {
    list-style: none;
    counter-reset: counter-3;
  }

  // list indents
  $listIndent: 30;

  .ul, .ol {
    position: relative;
    padding-left: $listIndent * 1px;

    &.indent-1 {
      padding-left: $listIndent * 2px;
    }

    &.indent-2 {
      padding-left: $listIndent * 3px;
    }

    &.indent-3 {
      padding-left: $listIndent * 4px;
    }
  }

  .ul::before {
    content: "•";
    font-size: 1.4em;
    padding-left: 5px;
    margin-top: -1px;
  }

  .ol::before,
  .ul::before {
    position: absolute;
    display: inline-block;
    margin-left: -15px;
    text-align: right;
    text-indent: -100px;
    white-space: nowrap;
  }

  .ul:empty::after,
  .ol:empty::after {
    content: " ";
    white-space: pre;
  }

  /* Our margin rules:

    ☝️ All things are spaced apart with a 24px margin...
      🌀 Except first children (of containers/rows)
      🌀 Except rows (which use 15px)
      🌀 Except successive lists and blockquotes
      🌀 Except headings
        ☝️ A heading has a tighter space below it for all immediate sibilings
          🌀 except other headings of higher rank (h1 has a higher rank than h2)
  */
  .ws-block,
  .ws-text {
    position: relative;
    margin-top: 24px;
  }

  .ws-block {
    break-inside: avoid;
  }

  .ws-row {
    margin-top: 20px - $column-padding-tb;
    margin-bottom: -2px;
  }

  .ws-hr {
    position: relative;
  }

  .ws-h1 {
    margin-top: 46px;

    @media print {
      margin-top: 24px;
    }
  }

  .ws-h2 {
    margin-top: 22px;
  }

  .ws-h3 {
    margin-top: 24px;
  }

  .ws-h4 {
    margin-top: 18px;
  }

  .ws-h5,
  .ws-h6 {
    margin-top: 18px;
  }

  :is(.ws-block, .ws-text, .ws-row):first-child {
    margin-top: 0;
    padding-top: 0;

    h1, h2, h3, h4, h5, h6 {
      margin-top: 0;
      padding-top: 0;
    }
  }

  .ws-quote {
    border-left: 2px solid currentColor;
  }

  .ws-quote + .ws-quote {
    margin-top: 0 !important;
    padding-top: 24px;
  }

  .ws-ol + .ws-ul,
  .ws-ul + .ws-ol,
  .ws-ol + .ws-ol,
  .ws-ul + .ws-ul {
    margin-top: 4px !important;
  }

  .ws-h1 + :is(.ws-text, .ws-block) {
    margin-top: 12px !important;
  }

  .ws-h2 + :is(.ws-text, .ws-block):not(.ws-h1) {
    margin-top: 8px !important;
  }

  .ws-h3 + :is(.ws-text, .ws-block):not(:is(.ws-h1, .ws-h2)) {
    margin-top: 4px !important;
  }

  :is(.ws-h4, .ws-h5, .ws-h6) + :is(.ws-text, .ws-block):not(:is(.ws-h1, .ws-h2, .ws-h3)) {
    margin-top: 4px !important;
  }

  .ws-break + .ws-block,
  .ws-break + .ws-text {
    margin-top: 0;
  }

  // inline element widths, as output by widthClass()

  // normal non-inline widths [.col_mode]
  .col_mode {
    max-width: calc(100% - 2px);
    display: inline-block;
    line-height: 28px;

    &.w_xsmall { width: $XSMALL; }
    &.w_small { width: $SMALL; }
    &.w_medium { width: $MEDIUM; }
    &.w_large { width: $LARGE; }
    &.w_xlarge { width: $XLARGE; }

    &.w_full {
      // 2px extra room for caret and line breaking behaviour
      width: calc(100% - 2px);
    }

    .inputWrap {
      line-height: 23px;
    }

    .label {
      text-align: left;
      display: block;
      line-height: 1.2658em;
      margin: 0;
      padding: 0.19em 0;
    }
  }

  h1 .col_mode .label {
    line-height: calc(var(--h1-size) * 0.92);
  }

  h2 .col_mode .label {
    line-height: calc(var(--h2-size) * 0.93);
  }

  h3 .col_mode .label {
    line-height: calc(var(--h3-size) * 0.94);
  }

  h4 .col_mode .label {
    line-height: calc(var(--h4-size) * 0.95);
  }

  // text ("inline flow") widths [.txt_mode]
  .txt_mode {
    display: inline;

    // note that when a width is applied to an inline, the value no
    // longer flows/line-breaks but instead becomes inline-block.
    &.w_xsmall .value, &.w_small .value, &.w_medium .value,
    &.w_large .value, &.w_xlarge .value, &.w_full .value {
      display: inline-block;
      max-width: calc(100% - 2px);
    }

    &.w_xsmall .value { width: $XSMALL; }
    &.w_small .value { width: $SMALL; }
    &.w_medium .value { width: $MEDIUM; }
    &.w_large .value { width: $LARGE; }
    &.w_xlarge .value { width: $XLARGE; }
    &.w_full .value { width: calc(100% - 2px) !important; }

    &.size_medium {
      padding: 6px 1px;
    }

    &.size_large {
      padding: 12px 1px;
    }
  }

  .row_mode {
    // 2px extra room for caret and line breaking behaviour
    width: calc(100% - 2px);
    margin: 2px 0;

    > .ctrl {
      display: flex;
    }

    .label {
      line-height: 1.2;
      align-content: start;
      padding: 0;
      flex-grow: 1;
      align-self: center;
    }

    &.element_radio .label {
      align-self: baseline;
    }

    .value {
      text-align: left;
      align-self: baseline;
      flex-grow: 0;
      flex-shrink: 0;
    }

    // these widths are tuned to match the layout column breakpoints
    &.w_xsmall .value { width: calc(25% - 37px); }
    &.w_small .value { width: calc(33% - 31px); }
    &.w_medium .value { width: calc(50% - 25px); }
    &.w_large .value { width: calc(66% - 12px); }
    &.w_xlarge .value { width: calc(75% - 12px); }

    // full width in row mode means label takes up the space it needs (up to 50%)
    // and the input fills up the remaining width of the control
    &.w_full .label {
      width: unset;
      flex-shrink: 1;
      flex-grow: 0;
      max-width: calc(50% - 25px);
    }

    &.w_full .value {
      width: unset;
      flex-shrink: 1;
      flex-grow: 1;
    }
  }
}
