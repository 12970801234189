@import "styles";

.button {
  @include resetButton();
  @include defaultFocus();

  position: absolute;
  right: 8px;
  border-radius: 4px;
  height: 24px;
  background-color: $white;
  transition: background-color 240ms ease;

  &.leftIcon {
    left: 8px;
    right: initial;

    &.small {
      left: 4px;
    }
  }

  svg path {
    color: $ash;
  }

  @include customKeyboardFocus() {
    transition: background-color 240ms ease;
    background-color: $sweet-rocket;

    svg path {
      color: $grid-purple;
    }
  }

  &:hover {
    background-color: $wise-owl;
    transition: background-color 240ms ease;
  }

  &.small {
    right: 4px;
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.icon {
  display: flex;
  align-items: center;
  padding-right: 4px;
  padding-left: 4px;

  &.hasError {
    svg path {
      color: $candy-apple;
    }
  }

  &.withoutAction {
    display: inline-flex;
    position: absolute;
    right: 8px;

    &.small {
      right: 4px;
    }

    &.leftIcon {
      left: 8px;
      right: initial;

      &.small {
        left: 4px;
      }
    }
  }
}
