@import "styles";

.print {
  display: none;
}

@media print {
  * {
    overflow: visible !important;
    position: relative;
    transition: none !important;
  }

  svg {
    max-width: 100%;
    overflow: hidden !important;
  }

  pre {
    overflow: hidden !important;
    font-size: 11px;
  }

  .no-print {
    display: none;
  }

  .print {
    display: unset;
  }

  /* stylelint-disable-next-line selector-id-pattern */
  #__next {
    padding-top: 0 !important;
  }
}

@media print {
  div#hubspot-messages-iframe-container {
    display: none !important;
  }
}
