.avatar {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;

  img {
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
  }
}
