@import "styles";

.header {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 12px;

  .goBack {
    margin-top: 2px;
  }

  .text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-left: 4px;
    max-width: 275px;

    @include breakpoint(medium) {
      max-width: 320px;
    }

    .subTitle {
      line-height: 14px;
      font-size: 12px;
      color: $fossil;
    }

    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: $squid-ink;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
