@import "styles";

.noEmbedAccess {
  display: flex;
  flex-flow: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  padding: 80px 40px;
  box-sizing: border-box;
  height: 100vh;
  overflow: hidden;
  background-color: $mist;

  &.canNotShare {
    p {
      max-width: 360px;
    }
  }

  p {
    @include paragraph16Regular();

    text-align: center;
    max-width: 400px;
  }

  a {
    @include links();

    color: $grid-purple;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
  }
}

.requestAccess {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 80px;
  animation: fadeIn 800ms;
  animation-fill-mode: backwards;

  h1 {
    @include display24Bold();
  }

  p {
    max-width: 400px;
    text-align: center;
  }

  @keyframes fadeIn {
    from { opacity: 0; }
  }

  .actions {
    margin-top: 24px;
    display: flex;
    flex-flow: column;
    align-items: center;
    position: relative;
    animation: fadeIn 800ms 200ms;
    animation-fill-mode: backwards;

    a {
      color: unset;
      text-decoration: none;
      cursor: pointer;
    }

    button {
      margin-top: 8px;
    }
  }

  .requested {
    display: flex;
    flex-flow: column;
    align-items: center;
    animation: fadeIn 400ms 400ms;
    animation-fill-mode: backwards;

    h2 {
      @include heading20Medium();

      display: flex;
      align-items: center;
      margin-bottom: 0;

      svg {
        margin-right: 4px;
        fill: $petit-pois;
      }
    }
  }

  .requesting {
    position: absolute;
    background: $white;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 40px;
    box-sizing: border-box;
    animation: fadeOut 400ms 1000ms;
    animation-fill-mode: forwards;

    @keyframes fadeOut {
      0% {
        opacity: 1;
      }

      99% {
        z-index: 1;
      }

      100% {
        opacity: 0;
        z-index: -1;
      }
    }
  }

  .hrule {
    border-top: 1px solid $wise-owl;
    width: 40px;
    margin: 24px 0 32px;
  }

  .popIn {
    display: inherit;
    animation: popIn 1000ms 1000ms ease-out;
    animation-fill-mode: backwards;

    @keyframes popIn {
      0% { transform: scale(0); }
      15%, 100% { transform: scale(1); }
      30%, 85% { transform: scale(1.5); }
    }
  }
}
