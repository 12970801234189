@import "styles";

.icon {
  fill: currentColor;
  color: currentColor;
  display: inline-block;
  vertical-align: middle;

  &.right {
    transform: rotate(90deg);
  }

  &.down {
    transform: rotate(180deg);
  }

  &.left {
    transform: rotate(270deg);
  }

  svg {
    display: block;
    fill: currentColor;
    color: currentColor;
  }

  .danger {
    color: $candy-apple;
    fill: $candy-apple;
  }

  .warning {
    color: $sunflower;
    fill: $sunflower;
  }

  .success {
    color: $petit-pois;
    fill: $petit-pois;
  }

  .info {
    color: $aster;
    fill: $aster;
  }

  .check {
    color: $petit-pois;
    fill: $petit-pois;
  }
}
