@import "styles";

.trigger {
  padding-left: 8px;
}

.body {
  display: flex;
  flex-direction: column;
  margin-left: -24px;
  margin-right: -24px;
  padding: 0 16px;

  .error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 32px;

    img {
      width: 200px;
      height: 200px;
    }
  }

  h5 {
    font-weight: 600;
  }
}

.footer {
  @include innerGap(16px);

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;

  .spacer {
    flex: 1;
  }
}
