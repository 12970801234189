@import "styles";

.wrapper {
  font-family: $font-main;
  min-width: 60px;
  width: 100%;
  min-height: 40px !important;
  height: 40px !important;
  font-size: 16px;
  color: $squid-ink;

  &.small {
    min-height: 32px !important;
    height: 32px !important;
    font-size: 14px;
  }

  &.large {
    min-height: 52px !important;
    height: 52px !important;
    font-size: 14px;
  }

  &.isMulti {
    height: auto !important;
  }

  .control {
    // We want the input to still be focusable so we still have the keyboard navigation functionality
    &.hideInput {
      order: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    &.small {
      min-height: 32px !important;
      height: 32px !important;
    }
  }

  .clearIndicator {
    cursor: pointer;
  }

  .dropdownIndicator {
    cursor: pointer;
    transition: transform 0.2s, color 0.15s;
    padding: 0;
    margin-right: 10px;

    &:hover {
      color: hsla(0deg, 0, 40%) !important;
    }
  }

  .openDropdownIndicator {
    transform: rotateX(180deg);
  }

  .valueContainer {
    line-height: 1.53;
    align-items: center;
    padding: 0 8px;
    margin-left: 8px;

    &.lite {
      padding: 0;
    }

    &.isSearchBox {
      .input {
        margin-left: 0;
      }
    }

    .input {
      margin-left: 8px;
    }
  }

  .placeholderWrapper {
    display: flex;
    color: $nobel;
  }

  .singleValue {
    display: flex;
    align-items: center;

    &.fullWidth,
    &.fullWidth > div {
      width: 100%;
    }

    .icon {
      padding-left: 0;
      margin-right: 4px;
    }
  }

  .icon {
    display: flex;
    width: 24px;
    height: 24px;

    &.small {
      width: 16px;
      height: 16px;
    }
  }

  .multiValuePill {
    border-right: none;
  }

  .searchBox {
    height: 56px;
    background: $white;
    border-radius: 0 0 6px 6px;
    box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%),
      0 4px 11px hsl(0deg 0% 0% / 10%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 12px;

    &.bottom {
      border-radius: 6px 6px 0 0;
    }

    &.transparent {
      background: $transparent;
      height: 90px;
    }

    .control {
      min-height: 32px;
      max-height: 32px;
      height: 32px;
      padding-left: 8px;
    }

    .icon {
      display: flex;
      height: 16px;
      width: 16px;
    }

    .valueContainer {
      padding-left: 0;
    }
  }
}

.menu {
  border-radius: 6px;

  &.isSearchBox:not(.hideInput) {
    border-radius: 6px 6px 0 0;

    &.bottom {
      border-radius: 0 0 6px 6px;
    }
  }

  .menuList {
    padding: 0;

    .tooltip {
      display: flex;
    }

    .option {
      color: $squid-ink;
      padding: 0;
      height: 48px;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      border-bottom: 1px solid $mist;
      width: 100%;
      cursor: pointer;

      &.small {
        height: 40px;

        .optionDescription {
          @include paragraph14Regular();

          font-size: 10px;
          line-height: 12px;
        }

        .optionTextWrapper {
          @include paragraph14Regular();

          font-size: 12px;
          line-height: 14px;
        }
      }

      &.large {
        height: 62px;

        .optionDescription {
          font-size: 12px;
        }

        .optionTextWrapper {
          font-size: 16px;
        }
      }

      &.disabled {
        opacity: 0.4;
        cursor: not-allowed;

        &.disabledFeature {
          cursor: pointer;
        }
      }

      &.hideBorders {
        border-bottom: none;
      }

      &.isSearchBox {
        .icon {
          padding-left: 12px;
        }
      }
    }

    .optionTextWrapper {
      color: $squid-ink;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding: 2px 0 0 8px;
      width: calc(100% - 8px);

      .optionText {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &.withIcon {
        width: calc(100% - 40px);
      }
    }

    .optionDescription {
      font-size: 12px;
      line-height: 16px;
      color: $ash;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.large {
        white-space: normal;
        overflow: initial;
        text-overflow: unset;
      }
    }

    .icon {
      padding-left: 8px;
      display: flex;

      img {
        vertical-align: initial;
      }

      &.large {
        width: 24px;
        height: 24px;
        padding-left: 12px;
      }
    }

    .group {
      padding: 0;
    }

    .groupHeading {
      @include label10Medium();

      margin: 0;
      height: 18px;
      background: $mist;
      color: $squid-ink;
      border-bottom: 1px solid $wise-owl;

      .textContent {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}

.noOptionsMessage,
.loadingMessage {
  @include label12Regular();

  height: 48px;
  display: flex;
  align-items: center;
}

.searchBoxInput {
  margin: 0;
}
