@import "styles";

.peoplePicker {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $mist;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  &.emptyPicker {
    background-color: $transparent;
  }

  .pills {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    box-sizing: border-box;
    padding: 8px;

    .pillWrapper {
      display: flex;
      padding-right: 8px;
      max-width: 100%;
      width: fit-content;

      &:first-of-type {
        max-width: 72%;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }

    &.noPills {
      padding: 0;
    }
  }

  .pickerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 38px;

    .emptyPicker {
      width: 100%;
      margin-right: 16px;
    }

    &.hasPills {
      display: block;

      .addMembers {
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }
  }
}

.title {
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 8px;
  color: $squid-ink;
}
