@import "styles";

.container {
  display: flex;
  flex-direction: column-reverse;
  min-width: 80px;
  width: 100%;

  &:hover:not(.disabled):not(.error),
  &:focus-within:not(.disabled):not(.error) {
    + span {
      color: $squid-ink;
    }
  }
}
