@import "styles";

.link {
  color: $grid-purple;
  text-decoration: none !important;
  position: relative;
  // XXX : Clean up, create a linear gradient that transitions from aster to squid ink and animate that
  background: linear-gradient($grid-purple, $grid-purple), linear-gradient($grid-purple, $grid-purple);
  background-size: 2px 1px, 100% 1px, 0 1px;
  background-position: 0 calc(100% - 3px);
  background-repeat: no-repeat;
  transition: all 120ms linear;
  font-size: 16px;
  font-weight: 600;

  &:hover {
    color: $squid-ink;
    background: linear-gradient($squid-ink, $squid-ink),
      linear-gradient($squid-ink, $squid-ink);
    background-size: 2px 2px, 100% 2px, 0 2px;
    background-position: 0 calc(100% - 2px);
    background-repeat: no-repeat;
    transition: all 120ms linear;
  }

  &[target="_blank"]::after {
    content: "Opens in new tab";
    position: absolute;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    border: 0;
  }

  &.x-small {
    font-size: 12px;
  }

  &.small {
    font-size: 14px;

    &:hover {
      background: linear-gradient($squid-ink, $squid-ink),
        linear-gradient($squid-ink, $squid-ink);
      background-size: 2px 1px, 100% 1px, 0 1px;
      background-position: 0 calc(100% - 3px);
      background-repeat: no-repeat;
      transition: all 120ms linear;
    }
  }

  &.large {
    font-size: 18px;
  }
}
