@import "styles";

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-10px); }
  20%, 40%, 60%, 80% { transform: translateX(10px); }
}

.shake {
  animation-duration: .7s;
  animation-fill-mode: both;
  animation-name: shake;
}

.passwordLock {
  flex-grow: 1;

  p {
    @include paragraph16Regular();

    margin: 0;
    margin-bottom: 24px;
  }

  .button {
    @include footerButtons();

    margin: 16px 0 0 auto;
  }
}

.signIn {
  text-align: center;
  border-top: 1px solid $wise-owl;
  margin: -16px -24px 0;
  padding: 24px 0 8px;

  @include breakpoint(medium) {
    padding: 24px 0 0;
  }

  a {
    text-decoration: underline;
  }
}

.fan {
  position: relative;
  transform: translateX(88%);
  width: 50vw;
  max-width: 160px;
  margin-top: 16px;
  margin-bottom: 64px;

  @include breakpoint(medium) {
    max-width: 200px;
  }

  @media screen and (min-width: 1280px) {
    max-width: 250px;
    transform: translateX(66%);
  }

  .base {
    position: relative;
    z-index: 1;
  }

  .blades {
    position: absolute;
    z-index: 0;
    width: 75%;
    top: 8%;
    left: 10%;
    animation: spinDown 2000ms ease-out;

    @keyframes spinDown {
      from { transform: rotate(-1920deg); }
      to { transform: rotate(0); }
    }
  }

  .faint {
    position: absolute;
    z-index: 0;
    width: 80%;
    top: 10%;
    left: 12%;
    opacity: 0;
    animation: spinDownFaint 2000ms cubic-bezier(0, 1, 1, .95);

    @keyframes spinDownFaint {
      0% {
        transform: rotate(-3720deg);
        opacity: 1;
      }

      90% {
        opacity: .5;
      }

      100% {
        transform: rotate(0);
        opacity: 0;
      }
    }
  }

  .shreds {
    position: absolute;
    top: 20%;
    left: -60%;
    width: 70%;
    z-index: 1;
    transform-origin: 150% 30%;
    animation: shred 1600ms 400ms;
    animation-fill-mode: backwards;

    @keyframes shred {
      from { transform: scale(0); }
      to { transform: scale(1); }
    }
  }

  .mouth {
    position: absolute;
    z-index: 1;
    width: 6%;
    top: 42%;
    left: 44%;
    animation: gasp 400ms 2000ms;
    animation-fill-mode: backwards;

    @keyframes gasp {
      from { transform: scale3d(1.2, .5, 1); }
      to { transform: scale3d(1, 1); }
    }
  }
}

.info {
  text-align: center;
  animation: fadeIn 400ms 2000ms;
  animation-fill-mode: backwards;
  display: flex;
  align-items: center;
  flex-direction: column;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-4px);
    }
  }

  p {
    margin-bottom: 32px;
  }

  button {
    animation: bounce 800ms 6000ms;

    @keyframes bounce {
      0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
      40% { transform: translateY(-6px); }
      60% { transform: translateY(-4px); }
    }
  }
}
