@import "styles";

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 80px;
  height: 40px;
  font-size: 16px;
  color: $squid-ink;
  line-height: 24px;
  box-sizing: border-box;
  padding: 9px 8px 7px;
  background: $white;

  @include insetBottomBorder($concrete, 1px);

  transition: box-shadow 240ms ease;

  &.small {
    padding: 4px 12px;
    height: 32px;
  }

  &:hover:not(.disabled):not(.error):not(:focus-within) {
    @include insetBottomBorder($grid-purple, 1px);

    transition: box-shadow 240ms ease;
  }

  &:focus-within {
    @include insetBottomBorder($grid-purple, 2px);

    transition: box-shadow 240ms ease;

    &:hover {
      @include insetBottomBorder($grid-purple, 2px);

      transition: box-shadow 240ms ease;
    }
  }

  &.error {
    @include insetBottomBorder($candy-apple, 2px);

    &:hover {
      @include insetBottomBorder($candy-apple, 2px);
    }

    &:focus-within {
      @include insetBottomBorder($candy-apple, 2px);
    }
  }

  &.disabled {
    @include insetBottomBorder($wise-owl, 1px);

    .input::placeholder {
      color: $fossil;
      transition: color 240ms ease;
    }

    :hover {
      cursor: not-allowed;
    }
  }

  &.afterIcon {
    padding-right: 32px;
  }

  &.beforeIcon {
    padding-left: 36px;

    &.small {
      padding-left: 32px;
    }
  }

  &.withBackground {
    background-color: $white;
  }

  &.search {
    @include insetBorder($concrete, 1px);

    border-radius: 8px;
    padding-top: 10px;
    padding-bottom: 10px;

    &.disabled {
      background-color: $cloud;
    }

    &:hover:not(.disabled):not(.error):not(:focus-within) {
      @include insetBorder($fossil, 1px);
    }

    &:focus-within {
      @include insetBorder($grid-purple, 1px);
    }

    &.error {
      @include insetBorder($candy-apple, 1px);

      &:hover {
        @include insetBorder($candy-apple, 1px);
      }

      &:focus-within {
        @include insetBorder($grid-purple, 1px);
      }
    }
  }

  &.textarea {
    @include insetBorder($transparent, 1px);

    background-color: $lily;
    height: unset;
    padding: 12px;
    border-radius: 4px;

    @include insetBorder($wise-owl, 1px);

    &:hover:not(.disabled):not(.error):not(:focus-within) {
      @include insetBorder($grid-purple, 1px);
    }

    &:focus-within {
      @include insetBorder($grid-purple, 1px);
    }

    &.error {
      @include insetBorder($candy-apple, 1px);
    }

    &.disabled {
      background-color: $cloud;
      color: $fossil;
    }
  }

  .input {
    height: 100%;
    width: 100%;
    border: none;
    margin: 0;
    padding: 0;
    display: block;
    background: $transparent;
    font-size: inherit;
    color: inherit;
    font-family: $font-main;
    caret-color: $grid-purple;

    &.textarea {
      resize: none;
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $nobel;
      font-size: inherit;
      transition: color 240ms ease;
    }

    &:disabled:hover {
      cursor: not-allowed;
    }

    &::selection {
      background: $selection-color;
    }
  }
}
