@import "styles";

.spinner {
  display: inline-block;
  position: relative;
  width: 5em;
  height: 1em;
  font-size: 8px;

  &.delayed {
    animation: show 200ms 400ms;
    animation-fill-mode: backwards;

    @keyframes show {
      from { opacity: 0; }
      to { opacity: 1; }
    }
  }

  &.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.spinner div {
  position: absolute;
  top: 0;
  width: 1em;
  height: 1em;
  border-radius: 40%;
  background: $lupine;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.spinner div:nth-child(1) {
  left: 0;
  animation: spinner1 0.6s infinite;
}

.spinner div:nth-child(2) {
  left: 0;
  animation: spinner2 0.6s infinite;
}

.spinner div:nth-child(3) {
  left: 2em;
  animation: spinner2 0.6s infinite;
}

.spinner div:nth-child(4) {
  left: 4em;
  animation: spinner3 0.6s infinite;
}

@keyframes spinner1 {
  0% { transform: scale(0); }
  100% { transform: scale(1); }
}

@keyframes spinner3 {
  0% { transform: scale(1); }
  100% { transform: scale(0); }
}

@keyframes spinner2 {
  0% { transform: translate(0, 0); }
  100% { transform: translate(2em, 0); }
}
