@import "https://use.typekit.net/dbo4xhd.css";

@mixin fontTrueType($font-name, $file-name, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    font-display: swap;
    font-style: $style;
    font-weight: $weight;
    src: url("./fonts/" + $file-name + ".ttf") format("truetype");
  }
}

@mixin fontWoff($font-name, $file-name, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    font-display: swap;
    font-style: $style;
    font-weight: $weight;
    src:
      local("☺"),
      url("./fonts/" + $file-name + ".woff2") format("woff2"),
      url("./fonts/" + $file-name + ".woff") format("woff");
  }
}

// -- used by our SheetUI
@include fontTrueType(Carlito, "carlito-regular", 400, normal);
@include fontTrueType(Carlito, "carlito-italic", 400, italic);
@include fontTrueType(Carlito, "carlito-bold", 700, normal);
@include fontTrueType(Carlito, "carlito-bolditalic", 700, italic);
@include fontTrueType(Caladea, "caladea-regular", 400, normal);
@include fontTrueType(Caladea, "caladea-italic", 400, italic);
@include fontTrueType(Caladea, "caladea-bold", 700, normal);
@include fontTrueType(Caladea, "caladea-bolditalic", 700, italic);
@include fontTrueType(Aptos, "aptos-regular", 400, normal);
@include fontTrueType(Aptos, "aptos-italic", 400, italic);
@include fontTrueType(Aptos, "aptos-bold", 700, normal);
@include fontTrueType(Aptos, "aptos-bolditalic", 700, italic);
@include fontTrueType("Aptos Narrow", "aptos_narrow-regular", 400, normal);
@include fontTrueType("Aptos Narrow", "aptos_narrow-italic", 400, italic);
@include fontTrueType("Aptos Narrow", "aptos_narrow-bold", 700, normal);
@include fontTrueType("Aptos Narrow", "aptos_narrow-bolditalic", 700, italic);

// -- used by UI and documents
// Source Sans Pro
@include fontWoff("Source Sans Pro", "SourceSans3-Regular.otf", 400, normal);
@include fontWoff("Source Sans Pro", "SourceSans3-It.otf", 400, italic);
@include fontWoff("Source Sans Pro", "SourceSans3-Semibold.otf", 500, normal);
@include fontWoff("Source Sans Pro", "SourceSans3-SemiboldIt.otf", 500, italic);
@include fontWoff("Source Sans Pro", "SourceSans3-Bold.otf", 700, normal);
@include fontWoff("Source Sans Pro", "SourceSans3-BoldIt.otf", 700, italic);
// Source Serif Pro
@include fontWoff("Source Serif Pro", "SourceSerif4-Regular.otf", 400, normal);
@include fontWoff("Source Serif Pro", "SourceSerif4-It.otf", 400, italic);
@include fontWoff("Source Serif Pro", "SourceSerif4-Semibold.otf", 500, normal);
@include fontWoff("Source Serif Pro", "SourceSerif4-SemiboldIt.otf", 500, italic);
@include fontWoff("Source Serif Pro", "SourceSerif4-Bold.otf", 700, normal);
@include fontWoff("Source Serif Pro", "SourceSerif4-BoldIt.otf", 700, italic);
// Proxima Nova
@include fontWoff("Proxima Nova", "Proxima Nova W02 Regular", 400, normal);
@include fontWoff("Proxima Nova", "Proxima Nova W02 Italic", 400, italic);
@include fontWoff("Proxima Nova", "Proxima Nova W02 Medium", 500, normal);
@include fontWoff("Proxima Nova", "Proxima Nova W02 Medium Italic", 500, italic);
@include fontWoff("Proxima Nova", "Proxima Nova W02 Bold", 700, normal);
@include fontWoff("Proxima Nova", "Proxima Nova W02 Bold Italic", 700, italic);
// Georgia with Georgia Pro semibolds
@include fontWoff("Georgia", "Georgia", 400, normal);
@include fontWoff("Georgia", "Georgia-Italic", 400, italic);
@include fontWoff("Georgia", "Georgia W05 SemiBold", 500, normal);
@include fontWoff("Georgia", "Georgia W05 SemiBold Italic", 500, italic);
@include fontWoff("Georgia", "Georgia-Bold", 600, normal);
@include fontWoff("Georgia", "Georgia-BoldItalic", 600, italic);

// - Google Fonts

// Arvo
@include fontWoff("Arvo", "arvo/arvo-regular", 400, normal);
@include fontWoff("Arvo", "arvo/arvo-italic", 400, italic);
@include fontWoff("Arvo", "arvo/arvo-700", 500, normal);
@include fontWoff("Arvo", "arvo/arvo-700italic", 500, italic);
@include fontWoff("Arvo", "arvo/arvo-700", 700, normal);
@include fontWoff("Arvo", "arvo/arvo-700italic", 700, italic);
// Bodoni Moda
@include fontWoff("Bodoni Moda", "bodoni-moda/bodoni-moda-regular", 400, normal);
@include fontWoff("Bodoni Moda", "bodoni-moda/bodoni-moda-italic", 400, italic);
@include fontWoff("Bodoni Moda", "bodoni-moda/bodoni-moda-500", 500, normal);
@include fontWoff("Bodoni Moda", "bodoni-moda/bodoni-moda-500italic", 500, italic);
@include fontWoff("Bodoni Moda", "bodoni-moda/bodoni-moda-700", 700, normal);
@include fontWoff("Bodoni Moda", "bodoni-moda/bodoni-moda-700italic", 700, italic);
// Cabin
@include fontWoff("Cabin", "cabin/cabin-regular", 400, normal);
@include fontWoff("Cabin", "cabin/cabin-italic", 400, italic);
@include fontWoff("Cabin", "cabin/cabin-500", 500, normal);
@include fontWoff("Cabin", "cabin/cabin-500italic", 500, italic);
@include fontWoff("Cabin", "cabin/cabin-700", 700, normal);
@include fontWoff("Cabin", "cabin/cabin-700italic", 700, italic);
// Chivo
@include fontWoff("Chivo", "chivo/chivo-regular", 400, normal);
@include fontWoff("Chivo", "chivo/chivo-italic", 400, italic);
@include fontWoff("Chivo", "chivo/chivo-700", 500, normal);
@include fontWoff("Chivo", "chivo/chivo-700italic", 500, italic);
@include fontWoff("Chivo", "chivo/chivo-700", 700, normal);
@include fontWoff("Chivo", "chivo/chivo-700italic", 700, italic);
// Courier Prime
@include fontWoff("Courier Prime", "courier-prime/courier-prime-regular", 400, normal);
@include fontWoff("Courier Prime", "courier-prime/courier-prime-italic", 400, italic);
@include fontWoff("Courier Prime", "courier-prime/courier-prime-700", 500, normal);
@include fontWoff("Courier Prime", "courier-prime/courier-prime-700italic", 500, italic);
@include fontWoff("Courier Prime", "courier-prime/courier-prime-700", 700, normal);
@include fontWoff("Courier Prime", "courier-prime/courier-prime-700italic", 700, italic);
// DM Sans
@include fontWoff("DM Sans", "dm-sans/dm-sans-regular", 400, normal);
@include fontWoff("DM Sans", "dm-sans/dm-sans-italic", 400, italic);
@include fontWoff("DM Sans", "dm-sans/dm-sans-500", 500, normal);
@include fontWoff("DM Sans", "dm-sans/dm-sans-500italic", 500, italic);
@include fontWoff("DM Sans", "dm-sans/dm-sans-700", 700, normal);
@include fontWoff("DM Sans", "dm-sans/dm-sans-700italic", 700, italic);
// Domine
@include fontWoff("Domine", "domine/domine-regular", 400, normal);
@include fontWoff("Domine", "domine/domine-500", 500, normal);
@include fontWoff("Domine", "domine/domine-700", 700, normal);
// EB Garamond
@include fontWoff("EB Garamond", "eb-garamond/eb-garamond-regular", 400, normal);
@include fontWoff("EB Garamond", "eb-garamond/eb-garamond-italic", 400, italic);
@include fontWoff("EB Garamond", "eb-garamond/eb-garamond-500", 500, normal);
@include fontWoff("EB Garamond", "eb-garamond/eb-garamond-500italic", 500, italic);
@include fontWoff("EB Garamond", "eb-garamond/eb-garamond-700", 700, normal);
@include fontWoff("EB Garamond", "eb-garamond/eb-garamond-700italic", 700, italic);
// Fira Sans
@include fontWoff("Fira Sans", "fira-sans/fira-sans-regular", 400, normal);
@include fontWoff("Fira Sans", "fira-sans/fira-sans-italic", 400, italic);
@include fontWoff("Fira Sans", "fira-sans/fira-sans-500", 500, normal);
@include fontWoff("Fira Sans", "fira-sans/fira-sans-500italic", 500, italic);
@include fontWoff("Fira Sans", "fira-sans/fira-sans-700", 700, normal);
@include fontWoff("Fira Sans", "fira-sans/fira-sans-700italic", 700, italic);
// Inter
@include fontWoff("Inter", "inter/inter-regular", 400, normal);
@include fontWoff("Inter", "inter/inter-500", 500, normal);
@include fontWoff("Inter", "inter/inter-700", 700, normal);
// Inter Light
@include fontWoff("Inter Light", "inter/inter-300", 400, normal);
@include fontWoff("Inter Light", "inter/inter-regular", 500, normal);
@include fontWoff("Inter Light", "inter/inter-500", 700, normal);
// Josefin Sans
@include fontWoff("Josefin Sans", "josefin-sans/josefin-sans-regular", 400, normal);
@include fontWoff("Josefin Sans", "josefin-sans/josefin-sans-italic", 400, italic);
@include fontWoff("Josefin Sans", "josefin-sans/josefin-sans-500", 500, normal);
@include fontWoff("Josefin Sans", "josefin-sans/josefin-sans-500italic", 500, italic);
@include fontWoff("Josefin Sans", "josefin-sans/josefin-sans-700", 700, normal);
@include fontWoff("Josefin Sans", "josefin-sans/josefin-sans-700italic", 700, italic);
// Libre Baskerville
@include fontWoff("Libre Baskerville", "libre-baskerville/libre-baskerville-regular", 400, normal);
@include fontWoff("Libre Baskerville", "libre-baskerville/libre-baskerville-italic", 400, italic);
@include fontWoff("Libre Baskerville", "libre-baskerville/libre-baskerville-700", 500, normal);
@include fontWoff("Libre Baskerville", "libre-baskerville/libre-baskerville-700", 700, normal);
// Libre Franklin
@include fontWoff("Libre Franklin", "libre-franklin/libre-franklin-regular", 400, normal);
@include fontWoff("Libre Franklin", "libre-franklin/libre-franklin-italic", 400, italic);
@include fontWoff("Libre Franklin", "libre-franklin/libre-franklin-500", 500, normal);
@include fontWoff("Libre Franklin", "libre-franklin/libre-franklin-500italic", 500, italic);
@include fontWoff("Libre Franklin", "libre-franklin/libre-franklin-700", 700, normal);
@include fontWoff("Libre Franklin", "libre-franklin/libre-franklin-700italic", 700, italic);
// Lora
@include fontWoff("Lora", "lora/lora-regular", 400, normal);
@include fontWoff("Lora", "lora/lora-italic", 400, italic);
@include fontWoff("Lora", "lora/lora-500", 500, normal);
@include fontWoff("Lora", "lora/lora-500italic", 500, italic);
@include fontWoff("Lora", "lora/lora-700", 700, normal);
@include fontWoff("Lora", "lora/lora-700italic", 700, italic);
// Merriweather
@include fontWoff("Merriweather", "merriweather/merriweather-regular", 400, normal);
@include fontWoff("Merriweather", "merriweather/merriweather-italic", 400, italic);
@include fontWoff("Merriweather", "merriweather/merriweather-700", 500, normal);
@include fontWoff("Merriweather", "merriweather/merriweather-700italic", 500, italic);
@include fontWoff("Merriweather", "merriweather/merriweather-700", 700, normal);
@include fontWoff("Merriweather", "merriweather/merriweather-700italic", 700, italic);
// Montserrat
@include fontWoff("Montserrat", "montserrat/montserrat-regular", 400, normal);
@include fontWoff("Montserrat", "montserrat/montserrat-italic", 400, italic);
@include fontWoff("Montserrat", "montserrat/montserrat-500", 500, normal);
@include fontWoff("Montserrat", "montserrat/montserrat-500italic", 500, italic);
@include fontWoff("Montserrat", "montserrat/montserrat-700", 700, normal);
@include fontWoff("Montserrat", "montserrat/montserrat-700italic", 700, italic);
// Nunito
@include fontWoff("Nunito", "nunito/nunito-regular", 400, normal);
@include fontWoff("Nunito", "nunito/nunito-italic", 400, italic);
@include fontWoff("Nunito", "nunito/nunito-600", 500, normal);
@include fontWoff("Nunito", "nunito/nunito-600italic", 500, italic);
@include fontWoff("Nunito", "nunito/nunito-700", 700, normal);
@include fontWoff("Nunito", "nunito/nunito-700italic", 700, italic);
// Open Sans
@include fontWoff("Open Sans", "open-sans/open-sans-regular", 400, normal);
@include fontWoff("Open Sans", "open-sans/open-sans-italic", 400, italic);
@include fontWoff("Open Sans", "open-sans/open-sans-500", 500, normal);
@include fontWoff("Open Sans", "open-sans/open-sans-500italic", 500, italic);
@include fontWoff("Open Sans", "open-sans/open-sans-700", 700, normal);
@include fontWoff("Open Sans", "open-sans/open-sans-700italic", 700, italic);
// Open Sans Medium
@include fontWoff("Open Sans Medium", "open-sans/open-sans-500", 400, normal);
@include fontWoff("Open Sans Medium", "open-sans/open-sans-500italic", 400, italic);
@include fontWoff("Open Sans Medium", "open-sans/open-sans-500", 500, normal);
@include fontWoff("Open Sans Medium", "open-sans/open-sans-500italic", 500, italic);
@include fontWoff("Open Sans Medium", "open-sans/open-sans-700", 700, normal);
@include fontWoff("Open Sans Medium", "open-sans/open-sans-700italic", 700, italic);
// Overpass
@include fontWoff("Overpass", "overpass/overpass-regular", 400, normal);
@include fontWoff("Overpass", "overpass/overpass-italic", 400, italic);
@include fontWoff("Overpass", "overpass/overpass-600", 500, normal);
@include fontWoff("Overpass", "overpass/overpass-600italic", 500, italic);
@include fontWoff("Overpass", "overpass/overpass-700", 700, normal);
@include fontWoff("Overpass", "overpass/overpass-700italic", 700, italic);
// Playfair Display
@include fontWoff("Playfair Display", "playfair-display/playfair-display-regular", 400, normal);
@include fontWoff("Playfair Display", "playfair-display/playfair-display-italic", 400, italic);
@include fontWoff("Playfair Display", "playfair-display/playfair-display-500", 500, normal);
@include fontWoff("Playfair Display", "playfair-display/playfair-display-500italic", 500, italic);
@include fontWoff("Playfair Display", "playfair-display/playfair-display-700", 700, normal);
@include fontWoff("Playfair Display", "playfair-display/playfair-display-700italic", 700, italic);
// Poppins
@include fontWoff("Poppins", "poppins/poppins-regular", 400, normal);
@include fontWoff("Poppins", "poppins/poppins-italic", 400, italic);
@include fontWoff("Poppins", "poppins/poppins-600", 500, normal);
@include fontWoff("Poppins", "poppins/poppins-600italic", 500, italic);
@include fontWoff("Poppins", "poppins/poppins-700", 700, normal);
@include fontWoff("Poppins", "poppins/poppins-700italic", 700, italic);
// Raleway
@include fontWoff("Raleway", "raleway/raleway-regular", 400, normal);
@include fontWoff("Raleway", "raleway/raleway-italic", 400, italic);
@include fontWoff("Raleway", "raleway/raleway-500", 500, normal);
@include fontWoff("Raleway", "raleway/raleway-500italic", 500, italic);
@include fontWoff("Raleway", "raleway/raleway-700", 700, normal);
@include fontWoff("Raleway", "raleway/raleway-700italic", 700, italic);
// Roboto
@include fontWoff("Roboto", "roboto/roboto-regular", 400, normal);
@include fontWoff("Roboto", "roboto/roboto-italic", 400, italic);
@include fontWoff("Roboto", "roboto/roboto-500", 500, normal);
@include fontWoff("Roboto", "roboto/roboto-500italic", 500, italic);
@include fontWoff("Roboto", "roboto/roboto-700", 700, normal);
@include fontWoff("Roboto", "roboto/roboto-700italic", 700, italic);
// Roboto Slab
@include fontWoff("Roboto Slab", "roboto-slab/roboto-slab-regular", 400, normal);
@include fontWoff("Roboto Slab", "roboto-slab/roboto-slab-500", 500, normal);
@include fontWoff("Roboto Slab", "roboto-slab/roboto-slab-700", 700, normal);
// Roboto Mono
@include fontTrueType("Roboto Mono", "roboto-mono/roboto-mono-variable", 400, normal);
// Rubik
@include fontWoff("Rubik", "rubik/rubik-regular", 400, normal);
@include fontWoff("Rubik", "rubik/rubik-italic", 400, italic);
@include fontWoff("Rubik", "rubik/rubik-500", 500, normal);
@include fontWoff("Rubik", "rubik/rubik-500italic", 500, italic);
@include fontWoff("Rubik", "rubik/rubik-700", 700, normal);
@include fontWoff("Rubik", "rubik/rubik-700italic", 700, italic);
// Space Mono
@include fontWoff("Space Mono", "space-mono/space-mono-regular", 400, normal);
@include fontWoff("Space Mono", "space-mono/space-mono-italic", 400, italic);
@include fontWoff("Space Mono", "space-mono/space-mono-700", 500, normal);
@include fontWoff("Space Mono", "space-mono/space-mono-700italic", 500, italic);
@include fontWoff("Space Mono", "space-mono/space-mono-700", 700, normal);
@include fontWoff("Space Mono", "space-mono/space-mono-700italic", 700, italic);
// Work Sans
@include fontWoff("Work Sans", "work-sans/work-sans-regular", 400, normal);
@include fontWoff("Work Sans", "work-sans/work-sans-italic", 400, italic);
@include fontWoff("Work Sans", "work-sans/work-sans-500", 500, normal);
@include fontWoff("Work Sans", "work-sans/work-sans-500italic", 500, italic);
@include fontWoff("Work Sans", "work-sans/work-sans-700", 700, normal);
@include fontWoff("Work Sans", "work-sans/work-sans-700italic", 700, italic);
