@import "styles";

.wrapper {
  position: fixed;
  bottom: 10px;
  width: 100%;
  left: 0;
  pointer-events: none;

  @include breakpoint(medium) {
    bottom: 20px;
  }
}

.toast {
  min-height: 44px;
  max-width: 680px;
  width: fit-content;
  margin: 0 auto;
  pointer-events: auto;
}
