@import "styles";

.userMenu {
  height: 32px;

  > * {
    background: transparent !important;
  }

  > *:hover img {
    filter: brightness(120%);
  }
}

.hideOnSmallScreens {
  @include editScreenSize(inline-flex);
}

.loginHref {
  text-decoration: none;

  .button {
    padding: 0 !important;

    @include breakpoint(medium) {
      padding: 0 24px !important;
    }
  }
}

.loadingAvatar {
  min-height: 40px;
  min-width: 40px;
  padding-left: 12px;
}

.buttonWrapper {
  display: flex;
  gap: 16px;

  @media (max-width: $designs-mobile) {
    gap: 8px;
  }
}

.resetButton {
  @include resetButton();

  &:focus {
    @include defaultFocus();
  }
}
