@import "styles";

.helperText {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 20px;
  color: $squid-ink;
  margin-top: 4px;
  white-space: break-spaces;

  .helperIcon {
    margin-right: 4px;
    margin-top: 2px;
  }

  &.disabled {
    color: $fossil;
  }

  &.error {
    color: $candy-apple;
  }
}
