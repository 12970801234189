@import "styles";

.navigation {
  z-index: $layer-header;

  &.topNav {
    position: sticky;
    top: 8px;
    right: 8px;
    margin-left: auto;
    width: max-content;
    padding: 8px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    background: rgba($white, 0.82);
    backdrop-filter: blur(2px);
    margin-bottom: 8px;

    > *:not(:first-of-type) {
      margin-left: 16px;
    }
  }

  &.mobileTopNav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: $shadow-md;
    box-sizing: border-box;
    background-color: $white;
    height: 56px;
    transition: transform 400ms;

    a {
      padding: 16px;
      text-decoration: none;
    }

    .mobileMenuTrigger {
      @include resetButton();

      padding: 16px;
    }
  }

  .notifications {
    height: 32px;
    margin-right: 16px;
    position: relative;
  }
}
