@import "./vars.module";
@import "./fonts";
@import "./mixins";
@import "./print";

:-webkit-full-screen {
  width: 100%;
  height: 100%;
  max-height: 100%;
}

body {
  background: $white;
  color: $squid-ink;
  margin: 0;
  padding: 0;
  font-family: $font-main;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size: 18px;
  line-height: 1.53;
  height: 100%;

  /* stylelint-disable */
  #__next {
    height: 100%;
  }
  /* stylelint-enable */
}

img {
  vertical-align: middle;
  max-width: 100%;
}

textarea {
  width: 100%;
  box-sizing: border-box;
}

a {
  @include links();
}

ol, ul {
  margin: .7rem 0;
  padding: 0 0 0 $step-x;
}

ol > li > ul,
ol > li > ol,
ul > li > ul,
ul > li > ol {
  margin: 0;
}

::selection {
  background: $selection-color;
}

::-moz-selection {
  background: $selection-color;
}

[data-slate-zero-width] {
  &::selection {
    background: transparent;
  }

  ::-moz-selection {
    background: transparent;
  }
}

[data-slate-node="text"] {
  [data-slate-leaf="true"] {
    [data-slate-zero-width] {
      ::selection {
        background: transparent;
      }

      ::-moz-selection {
        background: transparent;
      }
    }
  }
}

p {
  margin: .7rem 0;
}

h1 {
  font-size: 3rem;
  line-height: .95;
  font-weight: 400;
}

h2 {
  font-size: 2rem;
  line-height: 1.1;
  font-weight: 400;
}

h3 {
  font-size: 1.5rem;
  margin-top: 1.4rem;
  margin-bottom: 0.3rem;
  line-height: 1.2;
  font-weight: 400;
}

h4 {
  font-size: 1.1rem;
  font-weight: 600;
}

h5 {
  font-size: 1rem;
  font-weight: 600;
}

h6 {
  font-size: 1rem;
  font-weight: 600;
}

h4, h5, h6 {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-main;
}

strong {
  font-weight: 600;
}
