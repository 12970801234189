@import "styles";

$size: 20px;

.wrapper {
  display: inline-flex;
  position: relative;
  color: $squid-ink;
  height: $size;

  .input {
    position: absolute;
    overflow: hidden;
    height: 1px;
    width: 1px;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    top: 0;
    left: 0;

    + label {
      @include paragraph14Regular();

      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      padding-left: 28px;

      svg {
        border: 0;
        opacity: 0;
        position: absolute;
        left: 2px;
        top: 2px;
        transform: scale(0.8);
        transition: all 240ms ease;

        path {
          fill: $white;
        }
      }

      &::before {
        content: "";
        position: absolute;
        display: inline-block;
        box-sizing: border-box;
        margin-right: 8px;
        width: $size;
        min-width: $size;
        height: $size;
        background: $white;
        border-radius: 4px;
        border: 1px solid $fossil;
        top: 0;
        left: 0;
        transition: all 240ms ease;
      }
    }

    &:hover:not(:disabled) {
      + label {
        &::before {
          background: $cloud;
          border-color: $ash;
          transition: all 240ms ease;
        }
      }
    }

    &:disabled {
      + label {
        &::before {
          background: $lilac;
          border-color: $lilac;
          transition: all 240ms ease;
        }
      }
    }

    @include customKeyboardFocus() {
      + label {
        &::before {
          border-color: $fossil;
          box-shadow: 0 -1px 0 0 $grid-purple, 0 0 0 1px $grid-purple;
        }
      }
    }

    &:checked {
      + label {
        &::before {
          background: $grid-purple;
          border-color: $grid-purple;
          transition: all 240ms ease;
        }

        svg {
          opacity: 1;
          transform: scale(1);
          transition: all 240ms 140ms ease;
        }
      }

      &:hover {
        + label::before {
          background: $grid-purple;
          border-color: $grid-purple;
          transition: all 240ms ease;
        }
      }

      @include customKeyboardFocus() {
        + label::before {
          border-color: $grid-purple;
        }
      }

      &:disabled {
        + label::before {
          border-color: $lilac;
          background: $lilac;
          transition: all 240ms ease;
        }
      }
    }
  }
}
