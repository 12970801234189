@import "styles";

.label {
  color: $squid-ink;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 2px;

  .tooltipWrapper {
    margin-left: 2px;
    display: inline-flex;
    align-items: flex-start;

    .tooltipIcon {
      display: flex;
    }
  }

  &.disabled {
    color: $fossil;
  }

  &.error {
    color: $candy-apple;
  }
}
