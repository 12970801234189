@import "styles";

.members {
  margin: 0 -16px;
  border-bottom: 1px solid $mist;
  max-height: 280px;
  overflow-y: auto;
  margin-top: 8px;

  .member {
    padding: 0 0 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 52px;
    margin-bottom: 8px;

    .avatar {
      margin-right: 8px;
      width: 24px;
    }

    .text {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      max-width: calc(100% - 80px);
    }

    .subTitle {
      line-height: 16px;
      font-size: 12px;
      color: $fossil;
      white-space: nowrap;
    }

    .title {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: $squid-ink;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
