@import "styles";

.container {
  display: inline-block;
  position: relative;
  vertical-align: middle;

  .icon {
    color: $squid-ink;
  }

  .button {
    @include resetButton();

    display: flex;
    align-self: center;
    color: $squid-ink;
    border-radius: 8px;
    height: 32px;
    width: 32px;
    align-items: center;
    justify-content: center;

    &:hover {
      background: $mist;
    }

    &:focus {
      @include defaultFocus();
    }

    &.disabled {
      border-color: $fossil;
      opacity: .4;
      cursor: not-allowed;
    }
  }
}
