@import "styles";

$column: 101px;
$gutter: 15px;

.basePage {
  @media screen {
    // Absolutely position container.
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    // Flex children so banner controls viewport size.
    display: flex;
    flex-flow: column;
    flex-wrap: nowrap;
  }

  .site {
    // Site should fill the available space.
    flex-shrink: 1;
    flex-grow: 1;
    display: flex;
    flex-flow: row;
    overflow: hidden;
    position: relative;

    .content {
      flex-grow: 1;
      flex-shrink: 1;
      position: relative;
      transition: background 200ms;

      &:not(.disableOverflowAuto) {
        overflow: auto;
      }
    }
  }
}

.main {
  width: 100%;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
}

.loginLink:hover {
  text-decoration: none;
}

.full.main.embedded {
  display: flex;
  flex-flow: column;
  padding: 0;
}

.home {
  margin: 0;
  padding: 0;
  padding-top: 24px;
  display: flex;
  flex-flow: column;

  @media (min-width: $designs-mobile) {
    padding-left: 0;
    padding-top: 80px;
  }

  @media (min-width: $designs-desktop) {
    // minimum padding is 48px on both sides
    padding-top: 24px;
  }
}

@media (min-width: $designs-mobile) {
  .col4,
  .col6,
  .col8 {
    max-width: calc(#{$column * 4 + $gutter * 3} + #{$step-x} + #{$step-x});
  }
}

@media (min-width: $designs-tablet) {
  .col6,
  .col8 {
    max-width: calc(#{$column * 6 + $gutter * 5} + #{$step-x} + #{$step-x});
  }

  .headwrap {
    max-width: calc(
      #{$doc-width} + #{$head-extra-width} + #{$head-extra-width}
    );
  }
}

@media (min-width: $designs-desktop) {
  .col8 {
    max-width: calc(#{$column * 8 + $gutter * 7} + #{$step-x} + #{$step-x});
  }
}
