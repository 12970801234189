@import "styles";

.options {
  position: relative;
  z-index: $layer-modal;
  overflow-y: scroll;
  box-sizing: border-box;
  width: 100%;
  max-height: inherit;
  transition: position 1s ease 2s;
  -webkit-overflow-scrolling: touch;

  @media (max-width: $designs-tablet) {
    &.fullScreen {
      max-height: 100%;
    }
  }
}

.menuitem {
  cursor: pointer;

  &.selected {
    color: $squid-ink;
    background: $selection-color;
  }

  &.highlighted {
    color: $squid-ink;
    background: $mist;

    * { color: inherit; }
  }

  &.highlighted.selected {
    background: darken($selection-color, 5%);
  }

  &.disabled {
    border-color: $fossil;
    cursor: not-allowed;
    opacity: .4;
  }
}

.separator {
  background: $mist;
  margin-top: 4px;
  margin-bottom: 4px;
}

.group {
  .title {
    font-weight: 600;
    color: $squid-ink;
    cursor: default;
    font-size: 13px;
    line-height: 1.5;
    padding: 2px 4px;
  }

  .inner .menuitem {
    padding-left: 15px;
  }
}

.option {
  font-size: 15px;
  line-height: 1.35;
  padding: 4px;
}
