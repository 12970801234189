@import "styles";

.drawerWrapper {
  position: relative;

  &.upwards {
    position: absolute;
    top: 0;

    > * {
      bottom: 0;
    }

    &.left {
      right: 0;
      left: auto;
    }

    .drawer {
      &.arrow {
        margin: 0 0 5px;

        &::before {
          display: none;
        }

        &::after {
          content: " ";
          height: 10px;
          width: 10px;
          display: block;
          background: $white;
          position: absolute;
          bottom: -6px;
          transform-origin: center;
          transform: rotate(45deg);
          left: 12px;
        }
      }

      &.left {
        &.arrow {
          &::after {
            right: 12px;
            left: auto;
          }
        }
      }
    }
  }
}

.drawer {
  position: absolute;
  z-index: 10;
  background: $white;
  border-radius: 4px;
  box-shadow: 0 25px 50px rgba($squid-ink, 0.25);
  text-align: left;
  font-size: .9rem;
  margin: 0;
  padding: 0;
  left: 0;
  margin-top: 4px;

  &.left {
    right: 0;
    left: auto;

    &.arrow {
      &::before {
        right: 12px;
        left: auto;
      }

      &::after {
        right: 12px;
        left: auto;
      }
    }
  }

  &.arrow {
    margin: 5px 0 0;

    &::before {
      content: " ";
      height: 10px;
      width: 10px;
      display: block;
      background: $white;
      position: absolute;
      top: -6px;
      transform-origin: center;
      transform: rotate(45deg);
      left: 12px;
    }
  }

  .actionsWrapper {
    border-radius: 4px;
    overflow: auto;
    background: $white;
    position: relative;
    z-index: 2;
    padding: 8px 0;
  }

  .action {
    padding: 4px 16px 4px 12px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    min-width: 105px;
    min-height: 32px;
    box-sizing: content-box;
    color: $squid-ink;
    text-decoration: none;
    cursor: pointer;
    font-size: 16px;
    line-height: 22px;

    &.focused {
      background: $mist;
      color: $squid-ink;
    }

    &.disabled {
      pointer-events: none;
      cursor: not-allowed;
    }

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.input {
  @include visiblyHidden();
}

.menuPortal {
  position: absolute;
  z-index: $layer-toast;
  top: 0;
  left: 0;
  width: 400px;

  > * {
    margin: 0;
  }
}
