@import "styles";

.containerApi {
  /* The Feature branch colors are not part of our Design system as it's not user-facing it should be fine */
  /* stylelint-disable-next-line color-no-hex */
  background: linear-gradient(103.39deg, $pro-blue 13.11%, #e730ff 79.99%);
  bottom: 220px;
}

.containerClient {
  /* The Feature branch colors are not part of our Design system as it's not user-facing it should be fine */
  /* stylelint-disable-next-line color-no-hex */
  background: linear-gradient(103.39deg, #965cff 13.11%, #7d82ff 43.28%, $pro-blue 79.99%);
  bottom: 160px;
}

.container {
  position: fixed;
  padding: 8px;
  border-radius: 0 8px 8px 0;
  width: 400px;
  box-sizing: border-box;
  left: 0;
  cursor: pointer;
  transform: translateX(-348px);
  transition: transform 400ms 100ms;
  animation: sweepIn 0 ease 0;
  animation-fill-mode: backwards;
  color: $white;
  font-size: 16px;
  z-index: 1;

  &:hover {
    transform: translateX(0);

    section {
      height: 48px;
      transition: height 100ms;
    }
  }

  &.sweapIn {
    animation: sweepIn 4000ms ease 2000ms;

    section {
      animation: standTall 4200ms ease 2000ms;
    }
  }

  span {
    font-weight: 600;
    margin-right: 16px;
  }

  a {
    display: block;
    cursor: pointer;
    color: inherit;
    text-align: right;

    &:hover {
      text-decoration: none;
    }

    @media (max-width: $designs-mobile) {
      font-size: 16px;
    }
  }

  .icon {
    margin-left: 24px;
  }

  section {
    height: 48px;
    height: 0;
    transition: height 100ms 400ms;
  }

  // Mobile styling.
  @media (max-width: $designs-mobile) {
    animation: mobileAppear 400ms 2000ms;
    animation-fill-mode: backwards;
    bottom: 0;
    top: initial;
    height: 60px;
    width: 100%;
    transform: translate(calc((100% - 56px) * -1), 16px);
    border-radius: 0 16px 0 0;

    section {
      font-size: 12px;
      position: absolute;
      width: 100%;
      height: 40px;
      top: 16px;
      left: 8px;
    }

    a {
      margin-right: 8px;
    }
  }

  @keyframes mobileAppear {
    from {
      transform: none;
    }
  }

  @keyframes sweepIn {
    0% {
      transform: translateX(-400px);
    }

    10% {
      transform: translateX(0);
    }

    90% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-400px + 40px));
    }
  }

  @keyframes sweepUp {
    from {
      transform: translateY(60px);
    }

    to {
      transform: translateY(0);
    }
  }

  @keyframes standTall {
    0% {
      height: 48px;
    }

    95% {
      height: 48px;
    }

    100% {
      height: 0;
    }
  }
}
