@import "styles";

/* stylelint-disable color-no-hex */
/* stylelint-disable grid/no-color-if-var */

.dancingSliders {
  width: 400px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 18px;

  &.shouldAnimate section .wheel {
    animation-duration: 2000ms;
  }

  section {
    height: 66px;
    border-radius: 33px;
    display: flex;
    align-items: center;
    overflow: hidden;
    animation-duration: 400ms;
    animation-timing-function: ease-in-out;

    .wheel {
      width: 72px;
      height: 72px;
      border-radius: 50%;
      background: #fff;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }

    &:nth-child(1) {
      width: 187.5px;
      margin-left: 128px;
      background: linear-gradient(45deg, #85deff 0%, #3095ff 100%);
      animation-name: appearFromLeft;

      @keyframes appearFromLeft {
        from {
          opacity: 0;
          transform: translateX(-20%);
        }
      }

      .wheel {
        transform: translateX(36px);
        animation-name: w1;
        animation-delay: 200ms;

        @keyframes w1 {
          0%, 80%, 100% { transform: translateX(36px); }
          30%, 50% { transform: translateX(97px); }
        }
      }
    }

    &:nth-child(2) {
      width: 330px;
      margin-left: 35px;
      background: linear-gradient(135deg, #965cff 0%, #7d82ff 100%);
      animation-name: fadeIn;

      @keyframes fadeIn {
        from { opacity: 0; }
      }

      .wheel {
        height: 74px;
        width: 74px;
        transform: translateX(194px);
        animation-name: w2;
        animation-delay: 600ms;

        @keyframes w2 {
          0%, 70%, 100% { transform: translateX(194px); }
          30%, 40% { transform: translateX(34px); }
        }
      }
    }

    &:nth-child(3) {
      width: 231px;
      margin-left: 65px;
      background: linear-gradient(135deg, #965cff 0%, #7d82ff 100%);
      animation-name: appearFromRight;

      @keyframes appearFromRight {
        from {
          opacity: 0;
          transform: translateX(20%);
        }
      }

      .wheel {
        transform: translateX(96px);
        animation-name: w3;

        @keyframes w3 {
          0%, 80%, 100% { transform: translateX(96px); }
          30%, 50% { transform: translateX(36px); }
        }
      }
    }
  }
}

/* stylelint-enable color-no-hex */
/* stylelint-enable grid/no-color-if-var */
